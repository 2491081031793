import React, { useState, useEffect } from 'react'

function CreditsMovie({movieId}) {

    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5";
    const IMAGE_URL = "https://image.tmdb.org/t/p/original/"

    const [isLoading, setIsLoading] = useState(true)
    const [credits, setCredits] = useState();
    const [director, setDirector] = useState()

    useEffect(() => {
        
        async function getSingleMovie() {
            setIsLoading(true)
            try {
                const response = await fetch(`https://api.themoviedb.org/3/movie/${movieId}/credits?api_key=${api_key}`);
                const data = await response.json()
                if (data) {
                    setCredits(data);
                    console.log(data)
                } else {
                    console.log("Kunne ikke laste film")
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getSingleMovie()

    }, [movieId])

    useEffect(() => {

        if (credits) {
            const director = credits.crew.filter(crewMember => {
                return crewMember.job === "Director";
            })
            setDirector(director[0])
        }

    }, [credits])

    return (
        <div className="row">
            {director && (
                <div className="col-6 col-md-3 col-lg-2 my-3">
                    <div className="card">
                        <img src={director.profile_path ? `${IMAGE_URL}/${director.profile_path}` : "https://progitek.no/privat/bp/wp-content/uploads/2020/09/default.jpg"} alt={director.name} className="img-fluid" />
                        <div className="card-body">
                            <h6 className="font-weight-bold">{director.name}</h6>
                            <p className="text-muted">Regissør</p>
                        </div>
                    </div>
                </div>
            )}

            {credits && credits.cast && credits.cast.map((actor, index) => {
                return (
                    <div className="col-6 col-md-3 col-lg-2 my-3" key={index}>
                        <div className="card">
                            <img src={actor.profile_path ? `${IMAGE_URL}/${actor.profile_path}` : "https://progitek.no/privat/bp/wp-content/uploads/2020/09/default.jpg"} alt={actor.name} className="img-fluid" />
                            <div className="card-body">
                                <h6 className="font-weight-bold">{actor.name}</h6>
                                <p className="text-muted">{actor.character}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default CreditsMovie