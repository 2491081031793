import React, { useState, useEffect } from 'react'
import { firestore } from '../../../firebase'
import MoviesStreamService from './Templates/MoviesStreamService';

function StreamServices() {

    const [streamServices, setStreamServices] = useState([])

    useEffect(() => {

        const streamsRef = firestore.collection("stream-services");

        try {
            streamsRef.onSnapshot(({docs}) => {
                const streamServices = docs.map(doc => {
                    return {id: doc.id, ...doc.data()}
                })
                setStreamServices(streamServices)
            })
        } catch (error) {
            
        }

    }, [])

    return (
        
        <div className="mt-5">
            {streamServices && streamServices.map((stream, index) => {
                return (
                    <MoviesStreamService stream={stream} key={index} />
                )
            })}
        </div>
    )
}
export default StreamServices