import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Player from '../components/Player/Player'
import PlayerPc from '../components/Player/PlayerPc'
import { useQuery } from '../hooks/useQuery'

function VideoPage() {

    const {id} = useParams()
    let type = useQuery().get("type")    

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <button>PC PLAYER</button>
            {type === "pc" && <PlayerPc videoUrl="https://firebasestorage.googleapis.com/v0/b/movie-app-ff667.appspot.com/o/videos%2Fgladiator-trailer.mp4?alt=media&token=41198ade-ac09-4d76-b5bc-78329e2b705a" />}
            {type !== "pc" && <Player mediaId={id} />}
        </div>
    )
}
export default VideoPage