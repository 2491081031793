import { firestore } from "../firebase";

export async function getUserMovies(userId, setAdded, setWatchList, movieId) {
    try {
        console.log(movieId)
        const streamRef = firestore.collection("users").doc(userId).collection("movies");
        return streamRef.onSnapshot(({docs}) => {
            const streams = docs.map((doc) => {
                return {id: doc.id, ...doc.data()}
            })
            const filtered = streams.filter((stream) => {
                return stream.movieId === movieId
            })
            setWatchList(filtered)
            if (filtered.length) {
                setAdded(true)
            }
            
        })

    } catch (error) {
        
    }
}

export async function getPreviewByStreamId(setPreviews, streamId) {
    try {
        const previewRef = firestore.collection("previews");
        previewRef.where("stream_service_id", "==", streamId).onSnapshot(({docs}) => {
            const previews = docs.map(doc => {
                return {id: doc.id, ...doc.data()}
            })
            setPreviews(previews);
        })
    } catch (error) {
        
    }
}
export async function getPreviews(setPreviews) {
    try {
        const previewRef = firestore.collection("previews");
        previewRef.onSnapshot(({docs}) => {
            const previews = docs.map(doc => {
                return {id: doc.id, ...doc.data()}
            })
            setPreviews(previews);
        })
    } catch (error) {
        
    }
}