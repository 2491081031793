import React from 'react'
import { Link } from 'react-router-dom'

function PostListTemplateWp({ post }) {
    return (
        <div className="col-md-6 col-lg-4">
            <div className="card">
                <img src={''} alt="" className="img-fluid" />
                <div className="card-body">
                    <h4 className="font-weight-bold">
                        <Link to={`/posts-wp/${post.id}`}>
                            {post?.title?.rendered}
                        </Link>
                    </h4>
                </div>
            </div>
        </div>
    )
}
export default PostListTemplateWp
