import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { firestore } from '../../firebase'
import StateContext from '../../StateContext'
import AddPost from './AddPost'

function Posts() {

    const appState = useContext(StateContext)
    const [posts, setPosts] = useState([])

    const [show, setShow] = useState(false)

    useEffect(() => {

        try {
            const postsRef = firestore.collection("movie-blog");
            postsRef.onSnapshot(({docs}) => {
                const posts = docs.map(doc => {
                    return {id: doc.id, ...doc.data()}
                })
                setPosts(posts)
                console.log(posts, "posts")
            })
        } catch (error) {
            
        }

    }, [])

    function toggle() {
        if (show) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    function formatDate(datePost) {
        const date = new Date(datePost)

        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        return `${day}/${month}/${year}`    
    }

    return (
        <div className="pt-5 container">

            {appState.loggedIn && (
                <div className="my-5">
                    <button onClick={toggle}><i className={show ? "fas fa-times" : "fas fa-plus"}></i></button>
                    {show && (<AddPost />)}
                </div>
            )}
            <div className="row">
                {posts?.map((post, index) => {
                    return (
                        <div className="col-md-6 col-lg-4" key={index}>
                            <div className="card">
                                {post.featured_image && <Link to={`/posts/${post.id}`}><img alt="" src={post.featured_image} className="img-fluid" /></Link>}
                                <div className="card-body">
                                    <h4>
                                        <Link to={`/posts/${post.id}`}>{post.title}</Link>                                   
                                    </h4>
                                    <p><small>Av {post.author} {post.created_date && <span>den {formatDate(post.created_date)}</span>} </small></p>
                                    {appState.loggedIn && <Link to={`/posts/${post.id}/edit`}>Rediger</Link>}
                                    
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}
export default Posts