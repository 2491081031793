import React, { useEffect, useState } from 'react'
import { firestore } from '../../../../firebase';

function AddStreamServiceTV({tv}) {

    const [streamServices, setStreamServices] = useState([])
    const [show, setShow] = useState(false)

    useEffect(() => {

        try {
            const streamRef = firestore.collection("stream-services");
            return streamRef.onSnapshot(({docs}) => {
                const streams = docs.map((doc) => {
                    return {id: doc.id, ...doc.data()}
                })
                setStreamServices(streams)
            })
        } catch (error) {
            
        }

    }, [])

    async function submitHandler(stream) {
        try {
            const streamRef = firestore.collection("shows-streaming")

            const streamData = {
                tv_title: tv.original_name,
                stream_logo: stream.image,
                stream_service: stream.name,
                stream_service_id: stream.id,
                tmdb_id: tv.id,
                tv_poster: tv.poster_path
            }
            console.log(streamData)
            await streamRef.add(streamData);

        } catch (error) {
            console.log(error)
        }
    }

    function toggle() {
        if (show) {
            setShow(false);
        } else {
            setShow(true)
        }
    }

    return (
        <div>
            <h4 className="text-white">Legg til strømmetjeneste <i onClick={toggle} className="fas fa-plus"></i></h4>
            {show && (
                <div className="row">
                    {streamServices && streamServices.map((stream, index) => {
                        return (
                            <div className="col-3 col-lg-2 col-xl-1" key={index}>
                                <div className="card" onClick={() => submitHandler(stream)}>
                                    <img src={stream.image} alt={stream.name} />
                                </div>
                                {stream.type && (
                                    <div className="px-1 text-white">
                                        <small>{stream.type}</small>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )

}
export default AddStreamServiceTV