export const addToCache = (key, value) => {
    const stringifiedValue = JSON.stringify(value)
    localStorage.setItem(key, stringifiedValue)
}

export const getCacheByKey = (key) => {
    const value = localStorage.getItem(key);
    const valueParsed = JSON.parse(value);
    return valueParsed
}

export const removeCache = (key) => {
    localStorage.removeItem(key)
}