import React, { useEffect, useState } from 'react'
import { config } from '../../config/config'

function YoutubePlayer({trailers, play}) {
    const [video, setVideo] = useState()
    const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {
        console.log(trailers, "TRAILERS")
        if (trailers?.length > 0) {
            const key = trailers[0]?.key
            if (key) {
                setVideo(config.youtube_base + key)
                console.log(config.youtube_base + key)
            }
        }
        
    }, [trailers])

    useEffect(() => {
        if (play) {
            console.log("PLAY")
            setIsPlaying(isPlaying => !isPlaying)
        }
    }, [play])

    return (
        <iframe className="mediaPlayer" src={`${video}${isPlaying ? "?autoplay=1" : ""}`} allowfullscreen id='trailerVideo' frameborder='0'></iframe>
    )
}
export default YoutubePlayer