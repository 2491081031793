import React, { useEffect, useRef, useState } from 'react'
//import { firestore } from '../../firebase'
import {useHistory} from 'react-router-dom'
import './Player.css'
import { useKeyPress } from '../../hooks/useKeyPress'
import NextSuggestions from './NextSuggestions'
import { addToCache, getCacheByKey, removeCache } from '../../helpers/cache'
//import CurrentMediaDetails from './CurrentMediaDetails'
import { getSingleMovieById } from '../../api/api_tmdb'
import YoutubePlayer from './YoutubePlayer'
import CurrentMediaDetailsNetflix from './templates/CurrentMediaDetailsNetflix'
import { config } from '../../config/config'
function Player({mediaId}) {

	// Media ID is sent from clicked movie / show, and picked up in params. 
	let history = useHistory()
	const [video, setVideo] = useState()
	const [playUrl, setPlayUrl] = useState("https://firebasestorage.googleapis.com/v0/b/movie-app-ff667.appspot.com/o/videos%2Fgladiator-trailer.mp4?alt=media&token=41198ade-ac09-4d76-b5bc-78329e2b705a")
	const videoRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	const [show, setShow] = useState(false)
	const [playCount, setPlayCount] = useState(0)
	const [startTime, setStartTime] = useState(0)
	const [isPaused, setIsPaused] = useState(true)
	const [playMode, setPlayMode] = useState("video")
	const back = useKeyPress("b")
	const close = useKeyPress("Escape")
	const play = useKeyPress("p")
	const tvPlay = useKeyPress("MediaPlayPause")
	const enter = useKeyPress("Enter")
	const trailer = useKeyPress("t")
	const [movie, setMovie] = useState()
	const [progress, setProgress] = useState(0)

	function progressHandler() {
		console.log(videoRef?.current?.duration, "DURATION")
        if (videoRef?.current?.currentTime && videoRef?.current?.duration) {
            const percentagePlayed = Math.round((videoRef?.current?.currentTime / videoRef?.current?.duration) * 100)
			setProgress((percentagePlayed) || 0)
			//progress = percentagePlayed
		}
		//return progress
	}

	useEffect(() => {
		let progressInterval
		clearInterval(progressInterval)
		progressInterval = setInterval(() => {
			progressHandler()
		}, 1000)
	})

	useEffect(() => {
		async function getMediaData() {
			if (mediaId) {
				const data = await getSingleMovieById(mediaId);
				if (data) {
					setMovie(data)
					console.log(data)
				}
			}
		}
		getMediaData()
	}, [mediaId])

	useEffect(() => {
		/* try {
			const mediaRef = firestore.collection("videos").doc(mediaId)
			mediaRef.onSnapshot(doc => {
				const media = {id: doc.id, ...doc.data()}
				setVideo(media)
				setPlayUrl(media?.video_url)
			})
		} catch (error) {

		} */
	}, [mediaId])

	useEffect(() => {
		let timer
		clearInterval(timer)
		timer = setInterval(() => {
			if (videoRef?.current?.currentTime) {
				if (videoRef.current.currentTime > 0) {
					addToCache(`videoPlayDurationMedia${mediaId}`, videoRef?.current?.currentTime)
				}
			}
		}, 3000)
	}, [mediaId])

	useEffect(() => {
		const playDuration = getCacheByKey(`videoPlayDurationMedia${mediaId}`)
		if (playDuration) {
			setPlayCount(playCount => playCount + 1)
		}
	}, [mediaId])

	useEffect(() => {
		const playDuration = getCacheByKey(`videoPlayDurationMedia${mediaId}`)
		if (playDuration) {
			setStartTime(playDuration)
		}
	}, [])

	function routeChange(e) {
		history.goBack()
	}

	useEffect(() => {
		let timeout = null
		setIsVisible(true)
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			setIsVisible(false)
		}, 3000)
	}, [])

	useEffect(() => {
		if (back) {
			if (isVisible) {
				routeChange()
			} else {
				setIsVisible(true)
			}
		}
		if (close) {
			if (isVisible) {
				setIsVisible(false)
			}
		}
		if (play || enter || tvPlay) {
			if (videoRef?.current?.paused) {
				setIsPaused(false)
				setShow(false)
				const play = videoRef?.current?.play()
			} else {
				pauseVideo()
			}
		}
		if (trailer) {
			if (playMode === "trainer") {
				setPlayMode("video")
			} else {
				setPlayMode("trailer")
			}
		}
	}, [back, close, play, tvPlay, enter, trailer])

	useEffect(() => {
		setInterval(() => {
			console.log(videoRef)
			if (videoRef?.current && !show) {
				if (videoRef?.current?.ended) {
					console.log(videoRef.current.ended)
					setShow(true)
				}
			}
		}, 10000) 
	}, [videoRef])

	useEffect(() => {
		if (startTime > 0 && playCount < 3) {
			
			resumeVideo(startTime)
		}
	}, [startTime, playCount])

	/* useEffect(() => {
		setInterval(() => {
			if (videoRef?.current?.paused) {
				console.log("PAUSED")
				setIsPaused(true)
				setShow(true)
			}
		}, 1000)

	}, [videoRef?.current?.paused]) */

	useEffect(() => {
		console.log(isPaused, "ISPAUSED")
	}, [isPaused])

	function resumeVideo(startTime) {
		if (videoRef?.current && playCount < 2) {
			if (videoRef?.current) {
				videoRef.current.currentTime = startTime
				setShow(false)
				setIsPaused(false)
				setPlayCount(playCount => playCount + 1)
			}
		}
	}

	function pauseVideo() {
		/* setTimeout(() => {
			setIsPaused(true)
		}, 500) */
		setIsPaused(true)
		console.log(videoRef?.current?.pause())
	}

	function restartVideo() {
		videoRef.current.play()
	}

	function restartHandler() {
		removeCache(`videoPlayDurationMedia${mediaId}`)
		setStartTime(0)
		setShow(false)
		setIsPaused(false)
		videoRef.current.currentTime = 0;
		setShow(false)
		restartVideo()
	}

	useEffect(() => {
		window.addEventListener("keyup", (e) => {
			console.log(e.key)
		})
	}, [])

	useEffect(() => {
		if (playMode === "trailer") {
			// PLAY TRAILER
		}
		if (playMode === "video") {
			// RUN VIDEO, SET SHOW FALSE
		}
	}, [playMode])

	return (
		<div>
			<div className="watchContainer">
				<div className="videoControls watchNav" style={{visibility: isVisible ? "visible" : "hidden"}}>
					<button onClick={routeChange} className="transparent iconButton">
						<i className="fas fa-arrow-left"></i>
					</button>
					<h1>{video?.title || "Title"}</h1>
				</div>
				{playMode === "video" && (
					<video ref={videoRef} autoPlay className="mediaPlayer" style={{width: "100vw", height: "100vh"}}>
						<source src={playUrl} type="video/mp4" />
					</video>
				)}
				<div className="playerProgressContainer">
					<div className="playerProgressContainer__content">
						<div className="row no-gutters">
							<div className="col-1" onClick={isPaused ? () => resumeVideo(videoRef.current.currentTime) : () => pauseVideo()}>
								<span className="mx-auto">
									<i className={`fas fa-${isPaused ? "play" : "pause"} text-white fa-2x`}></i>
								</span>
							</div>
							<div className="col-10">
								<div className="progressBar mt-3" style={{width: "100%"}}>
									<div className="progressBarContent" style={{width: `${progress}%`, backgroundColor: "red"}}></div>
								</div>
							</div>
							<div className="col-1"></div>
						</div>
						
					</div>
				</div>
				{movie?.videos?.results && playMode === "trailer" && <YoutubePlayer trailers={movie?.videos?.results} play={play} />}
				{show && playMode === "video" && !isPaused && (
					<div className="nextSuggestions">
						<div className="nextSuggestions__inner">
							<h2 className="text-white font-weight-bold"><i className="fas fa-redo-alt" onClick={restartHandler}></i> Start over</h2>
							<NextSuggestions genreId={video?.genre_id || 28} type={video?.type || "movie"}/>
						</div>
					</div>
				)}
				{isPaused && playMode === "video" && (
					<div className="nextSuggestions backgroundImage" style={{width: "100%",backgroundImage: `url('${config.api_image_base_url + movie?.backdrop_path}')`}}>
						<div className="darkBackground">
							<div className="nextSuggestions__inner">
								{/* <CurrentMediaDetails movie={movie} /> */}
								<CurrentMediaDetailsNetflix movie={movie} videoRef={videoRef} resumeVideo={resumeVideo} restartVideo={restartHandler} setPlayMode={setPlayMode} progressPercentage={progress} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
export default Player
