import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

import MoviePagination from './MoviePagination'
import NavbarDark from '../Netflix/Templates/NavbarDark';
import Page from '../../ReusableComponents/Page';
import { getPopularTvShows, getTvByGenreId } from '../../../api/api_tmdb';
import Loader from '../../Loader/Loader';
import TvGenres from './Templates/TvGenres';
import { config } from '../../../config/config';

function TV() {

    const urlParams = new URLSearchParams(window.location.search);
    const genreId = urlParams.get("genreId")
    const [movies, setMovies] = useState([])
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [activePage, setActivePage] = useState("")
    const {page} = useParams()

    useEffect(() => {
        setActivePage("shows")
        async function getMovies() {
            setIsLoading(true);
            try {
                if (genreId) {
                    const data = await getTvByGenreId(page, genreId)
                    setMoviesLoaded(true)   
                    setMovies(data)
                } else {
                    const data = await getPopularTvShows(page)
                    setMoviesLoaded(true)   
                    setMovies(data)
                }
                
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getMovies()
    }, [page, genreId])

    return (
        <Page pageTitle="Shows">
            <div className="wrapper">
                <NavbarDark activePage={activePage} />
                <div className="container py-5">
                    <div className="row">
                        <div className="col-3">
                            <TvGenres />
                        </div>
                        <div className="col-9">
                            <div className="row no-gutters py-4">
                                {movies?.map(movie => {
                                    return (
                                        <div className="col-4 col-md-3 px-1 py-1" key={movie.id}>
                                            <div className="card">
                                                <Link to={`/shows/single/${movie.id}`}>
                                                    {movie.poster_path != null ? 
                                                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} alt={movie.title} className="img-fluid" /> : 
                                                    <img src={config.default_movie_poster} alt={movie.title} className="img-fluid" />}
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {isLoading && <Loader />}
                            <MoviePagination genreId={genreId} link="shows" currentPage={page} />
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    )
}
export default TV