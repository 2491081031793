import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getTvGenres } from '../../../../api/api_tmdb'

function TvGenres() {
    const urlParams = new URLSearchParams(window.location.search);
    const genreId = urlParams.get("genreId")
    const [genres, setGenres] = useState([])
    const [activeGenre, setActiveGenre] = useState(genreId || null)

    useEffect(() => {
        async function getAllGenres() {
            const data = await getTvGenres()
            setGenres(data)
        }
        getAllGenres()
    }, [])

    useEffect(() => {
        if (genreId) {
            setActiveGenre(genreId)
        }
    }, [genreId])

    return (
        <div className="mt-3">
            {genres?.map((genre, index) => {
                return (
                    <div key={index} className="text-white p-2">
                        <Link className={`btn-block ${+activeGenre === +genre.id ? "text-warning" : "text-primary"}`} to={`/shows/1?genreId=${genre.id}`}>{genre?.name}</Link>
                    </div>
                )
            })}
        </div>
    )
}
export default TvGenres