import React, { useState } from 'react'
import Accordion from './Accordion';

const AccordionWrapper = () => {

    const [expanded, setExpanded] = useState(0);
    const accordionIds = [0, 1, 2, 3];
    return accordionIds.map((i) => (
      <Accordion i={i} expanded={expanded} setExpanded={setExpanded} />
    ));

}
export default AccordionWrapper