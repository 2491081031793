import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getMoviesByGenreId } from '../../api/api_tmdb'
import { config } from '../../config/config'
import { getCacheByKey } from '../../helpers/cache'

function NextSuggestions({genreId, type}) {

	const [movies, setMovies] = useState()

	useEffect(() => {
		async function getSuggestions() {
			let dataLS = []
			if (type === "movie") {
				dataLS = getCacheByKey(`moviesGenre${genreId}`)
			} else {
				dataLS = getCacheByKey(`showsGenre${genreId}`);
			}
			if (dataLS?.movies?.length > 0) {
				setMovies(dataLS?.movies?.slice(0, 4))
			}
			if (dataLS?.movies?.length < 1) {
				const data = await getMoviesByGenreId(1, genreId);
				setMovies(data?.slice(0, 4));
			}
		}
		getSuggestions()
	}, [])

	return (
		<div>
			<h2 className="text-white font-weight-bold my-3">Forslag</h2>
			<div className="row">
				{movies?.map((movie, index) => {
					return (
						<div className="col-3" key={index}>
							<Link to={`/movies/single/${movie?.id}`}>
								<img src={config?.api_image_base_url + movie?.poster_path} alt="" />
							</Link>
						</div>
					)
				})}
			</div>
		</div>
	)
}
export default NextSuggestions
