import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Axios from 'axios'

import Loader from '../../Loader/Loader'
import NavbarDark from '../Netflix/Templates/NavbarDark'

function SearchShows() {

    const [searchTerm, setSearchTerm] = useState()

    const [movies, setMovies] = useState([])
    const [show, setShow] = useState()
    const [requestCount, setRequestCount] = useState(0)

    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5"

    useEffect(() => {
        
        if (searchTerm) {
            setShow("loading")
            setIsLoading(true);
            const delay = setTimeout(() => {
                setRequestCount(requestCount+1)
            }, 700)
    
            return () => clearTimeout(delay)
        } else {
            setShow("neither")
        }
        setIsLoading(false);

    }, [searchTerm])

    useEffect(() => {

        if (requestCount) {
            async function searchShows() {
                setIsLoading(true);
                try {
                    const response = await Axios.get(`https://api.themoviedb.org/3/search/tv?api_key=${api_key}&language=en-US&query=${searchTerm}&page=1&include_adult=false`)
                    console.log(response.data)
                    setMovies(response.data.results)
                    setShow("results")
                    setMoviesLoaded(true)
                } catch (error) {
                    console.log(error)
                }
                setIsLoading(false)
            }
            searchShows()
            
        } else {
            async function getMovies() {
                setIsLoading(true);
                try {
                    const response = await fetch("https://api.themoviedb.org/3/tv/popular?api_key=" + api_key)
                    const data = await response.json()
                    console.log(data.results)
                    setMovies(data.results)
                    setMoviesLoaded(true)
                } catch (error) {
                    console.log(error)
                }
                setIsLoading(false)
            }
    
            getMovies()
        }  

    }, [requestCount])

    function handleInput(e) {
        const value = e.target.value
        setSearchTerm(value);
        console.log(searchTerm)
        
    }

    

    return (
        <div className="bg-dark">
        <NavbarDark />
            <div className="search-overlay-top shadow-sm bg-dark" style={{paddingTop: "70px"}}>
                <div className="container">
                    <label htmlFor="live-search-field" className="search-overlay-icon">
                        <i className="fas fa-search"></i>
                    </label>
                    <input onChange={handleInput} autoFocus type="text" autoComplete="off" id="live-search-field" className="live-search-field" placeholder="Søk etter serier" />           
                </div>
            </div>

            <div className="container py-3">

                <Link to="/search-movies">Søk filmer</Link>
                    {isLoading ? <Loader /> : 

                    <div className="row no-gutters">
                        {movies.map(movie => {
                        return (
                        <div className="col-4 col-md-3 col-lg-2 px-1 py-1" key={movie.id}>
                            <div className="card">
                                <Link to={`/shows/single/${movie.id}`}>
                                    {movie.poster_path != null ? 
                                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} alt={movie.title} className="img-fluid" /> : 
                                    <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt={movie.title} className="img-fluid" />}
                                </Link>
                            </div>
                        </div>
                        )
                    })}
                    </div>}
                
            </div>
        </div>
        
    )
}
export default SearchShows