import React, { useEffect, useState } from 'react'

function TrailersMovie({trailers, backdrop}) {


    const [video, setVideo] = useState("")
    const [currentIndex, setCurrentIndex] = useState(0)

    const VIDEO_URL = "https://www.youtube.com/embed/"

    useEffect(() => {

        if (trailers && trailers.length) {
            console.log(trailers)
            setVideo(`${VIDEO_URL}${trailers[currentIndex].key}`)
            console.log(`${VIDEO_URL}${trailers[currentIndex].key}`)
        }

    }, [trailers, currentIndex])

    function changeVideo(type, index) {
        if (type === "next") {
            if (index === trailers.length-1) {
                setCurrentIndex(0)
            } else {
                setCurrentIndex(currentIndex+1)
            }
        }

        if (type === "prev") {
            if (index === 0) {
                setCurrentIndex(trailers.length -1)
            } else {
                setCurrentIndex(currentIndex-1)
            }
        }
    }

    return (
        <div className="col-12 py-5">
            <div className="container">
                <iframe src={video} allowfullscreen id='trailerVideo' frameborder='0'></iframe>
                {trailers && trailers.length > 1 && (
                    <div className="row">
                        <div className="col-6">
                            <button onClick={() => changeVideo("prev", currentIndex)}>Previous</button>
                        </div>
                        <div className="col-6">
                            <span className="float-right">
                                <button onClick={() => changeVideo("next", currentIndex)}>Next</button>
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )

}
export default TrailersMovie
