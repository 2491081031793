import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../../StateContext'
import { firestore } from '../../../../firebase'

function WatchListHandlerTV({ movie }) {
    const appState = useContext(StateContext)

    const [added, setAdded] = useState(false)
    const [watchList, setWatchList] = useState([])

    useEffect(() => {
        if (appState.loggedIn && appState.userId) {
            try {
                console.log(movie.id)
                const streamRef = firestore
                    .collection('users')
                    .doc(appState.userId)
                    .collection('shows')
                return streamRef.onSnapshot(({ docs }) => {
                    const streams = docs.map((doc) => {
                        return { id: doc.id, ...doc.data() }
                    })
                    const filtered = streams.filter((stream) => {
                        return stream.movieId === movie.id
                    })
                    setWatchList(filtered)
                    if (filtered.length) {
                        setAdded(true)
                    }
                })
            } catch (error) {}
        }
    }, [movie.id, appState.loggedIn])

    async function listHandler(e) {
        if (!appState.userId || !appState.loggedIn) {
            return
        }
        e.preventDefault()
        const moviesListRef = firestore
            .collection('users')
            .doc(appState.userId)
            .collection('shows')
        const createdAt = new Date()

        if (!added) {
            try {
                moviesListRef.add({
                    movieId: movie.id,
                    moviePoster: movie.poster_path,
                    createdAt,
                })
                setAdded(true)
            } catch (error) {
                setAdded(false)
            }
        } else {
            try {
                const singleMovieRef = firestore
                    .collection('users')
                    .doc(appState.userId)
                    .collection('shows')
                    .doc(watchList[0].id)
                singleMovieRef.delete()
                setAdded(false)
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <button onClick={listHandler}>
            <i className={added ? 'fas fa-check' : 'fas fa-plus'}></i> Min liste
        </button>
    )
}
export default WatchListHandlerTV
