import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {getMoviesBySearchTerm, getPopularMovies} from '../../../api/api_tmdb'
import Loader from '../../Loader/Loader'
import NavbarDark from '../Netflix/Templates/NavbarDark'

function SearchMovies() {

    const [searchTerm, setSearchTerm] = useState()
    const [movies, setMovies] = useState([])
    const [show, setShow] = useState()
    const [requestCount, setRequestCount] = useState(0)
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (searchTerm) {
            setShow("loading")
            setIsLoading(true);
            const delay = setTimeout(() => {
                setRequestCount(requestCount+1)
            }, 700)
    
            return () => clearTimeout(delay)
        } else {
            setShow("neither")
        }
        setIsLoading(false);
    }, [searchTerm])

    useEffect(() => {
        if (requestCount) {
            async function searchMovies() {
                setIsLoading(true);
                try {
                    const data = await getMoviesBySearchTerm(searchTerm)
                    setMovies(data)
                    setShow("results")
                    setMoviesLoaded(true)
                } catch (error) {
                    console.log(error)
                }
                setIsLoading(false)
            }
            searchMovies()
        } else {
            async function getMovies() {
                setIsLoading(true);
                try {
                    const data = await getPopularMovies(1)
                    setMovies(data)
                    setMoviesLoaded(true)
                } catch (error) {
                    console.log(error)
                }
                setIsLoading(false)
            }
            getMovies()
        }  
    }, [requestCount])

    function handleInput(e) {
        const value = e.target.value
        setSearchTerm(value);
    }

    return (
        <>
        <NavbarDark />
        
            <div className="search-overlay-top shadow-sm bg-dark " style={{paddingTop: "70px"}}>
                <div className="container">
                    <label htmlFor="live-search-field" className="search-overlay-icon">
                        <i className="fas fa-search"></i>
                    </label>
                    <input onChange={handleInput} autoFocus type="text" autoComplete="off" id="live-search-field" className="live-search-field" placeholder="Søk etter filmer" />           
                </div>
            </div>

            <div className="bg-dark">
                <div className="container py-3">
                    <Link to="/search-shows">Søk serier</Link>
                    <div className="row no-gutters">
                        {movies?.map(movie => {
                            return (
                                <div className="col-4 col-md-3 col-lg-2 px-1 py-1" key={movie.id}>
                                    <div className="card">
                                        <Link to={`/movies/single/${movie.id}`}>
                                            {movie.poster_path != null ? 
                                            <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} alt={movie.title} className="img-fluid" /> : 
                                            <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt={movie.title} className="img-fluid" />}
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {isLoading && <Loader />}
                </div>
            </div>
        </>
    )
}
export default SearchMovies