import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getYearByDate } from '../../../helpers/dateFormatter'
//import { config } from '../../../config/config'

function CurrentMediaDetailsNetflix({movie, videoRef, resumeVideo, restartVideo, setPlayMode, progressPercentage}) {

    const [activeItem, setActiveItem] = useState(1)

    function playTrailer() {
        setPlayMode("trailer")
    }

	return (
		<div className="text-white">
			<div className="col-md-8">
				<h2 className="font-weight-bold">{movie?.title}</h2>

                <div className="my-4">
                    <small>
                        <i className="fas fa-thumbs-up"></i> 
                        <span className="pl-3">{getYearByDate(movie?.release_date)} </span> 
                        <span className="bg-dark p-1 ml-2">{movie?.adult ? "18+" : "1+"}</span>
                        <span className="p-1 ml-3" style={{border: "2px solid white"}}>HD</span>
                    </small>
                </div>

				<p>{movie?.overview}</p>
				<div className="row">
                    <div className="col-md-6">
                        <div 
                            onClick={() => resumeVideo(videoRef?.current?.currentTime || 0)}
                            className={`text-white btn-block pointer netflixButton ${activeItem === 1 && "netflixButton__active"}`}
                        >
                            <div className="row">
                                <div className="col-6">
                                    <div className="pl-3">
                                        <i className="fas fa-play"></i> Resume
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className=" pt-2 px-2">
                                        <div className="progressBar" style={{width: "100%"}}>
                                            <div className="progressBarContent" style={{width: `${progressPercentage}%`, backgroundColor: "red"}}></div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div 
                            className={`text-white btn-block pl-3 pointer netflixButton ${activeItem === 2 && "netflixButton__active"}`}
                            onClick={restartVideo}   
                        >
                            <i className="fas fa-redo"></i> Play from beginning
                        </div>
                        <div 
                            onClick={playTrailer} 
                            className={`text-white btn-block pl-3 pointer netflixButton ${activeItem === 3 && "netflixButton__active"}`}
                        >
                            Trailer
                        </div>
                        <div className={`text-white btn-block pl-3 pointer netflixButton ${activeItem === 4 && "netflixButton__active"}`}>
                            <i className="fas fa-plus"></i> Min liste
                        </div>
                        <div 
                            className={`text-white btn-block pl-3 pointer netflixButton ${activeItem === 5 && "netflixButton__active"}`}
                        >
                            <i className="fas fa-th-large"></i> More like this
                        </div>
                        <div className={`text-white btn-block pl-3 pointer netflixButton ${activeItem === 6 && "netflixButton__active"}`}>
                            <Link className="text-white" to={`/video/${movie?.id}?type=pc`}><i className="fas fa-desktop"></i> Switch to PC mode</Link>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	)
}
export default CurrentMediaDetailsNetflix
