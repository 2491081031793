import React, { useState, useEffect } from 'react'
import { getWatchProviders } from '../../../api/api_tmdb';
import { config } from '../../../config/config';
import { firestore } from '../../../firebase';
import TmdbStreamsTemplate from './Templates/TmdbStreamsTemplate';

function MovieStreamServices({movieId}) {
    const [show, setShow] = useState(true)
    const [streams, setStreams] = useState([])
    const [tmdbStreams, setTmdbStreams] = useState([])

    useEffect(() => {
        async function getStreamingData() {
            const data = await getWatchProviders(movieId)
            console.log(data['NO'])
            if (data['NO']) {
                setTmdbStreams(data['NO'])
                console.log(data['NO'])
            }
        }
        getStreamingData()
    }, [movieId])

    useEffect(() => {

        try {
            const streamRef = firestore.collection("movies-streaming");
            return streamRef.where("tmdb_id", "==", movieId).onSnapshot(({docs}) => {
                const streams = docs.map((doc) => {
                    return {id: doc.id, ...doc.data()}
                })
                setStreams(streams)
            })

        } catch (error) {
            
        }

    }, [movieId])

    return (
        <React.Fragment>
            <button onClick={() => setShow(show => !show)}>{show ? "Lukk" : "Vis strømmetjenester"}</button>
            {show && (

            
            <div className="row">
                <div className="col-12">
                    <p className="text-white">Streams provided by JustWatch</p>
                    {tmdbStreams?.flatrate?.length ? <p className="text-white">Tilgjengelig for strømming på:</p> : ""}
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        {tmdbStreams?.flatrate?.map((stream, index) => {
                            return <TmdbStreamsTemplate key={index} stream={stream} />
                        })}
                    </div>
                </div>
                <div className="col-12 mt-3">
                    {tmdbStreams?.buy?.length ? <p className="text-white">Tilgjengelig for kjøp på:</p> : ""}
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        {tmdbStreams?.buy?.map((stream, index) => {
                            return <TmdbStreamsTemplate key={index} stream={stream} />
                        })}
                    </div>
                </div>
                <div className="col-12 mt-3">
                    {tmdbStreams?.rent?.length ? <p className="text-white">Tilgjengelig for leie på:</p> : ""}
                </div>
                <div className="col-lg-8">
                    <div className="row">
                        {tmdbStreams?.rent?.map((stream, index) => {
                            return <TmdbStreamsTemplate key={index} stream={stream} />
                        })}
                    </div>
                </div>
                {tmdbStreams?.flatrate?.length < 1 && streams?.map((stream, index) => {
                    return (
                        <div className="col-2 col-lg-1" key={index}>
                            <div className="card">
                            <img src={stream.stream_logo} alt={stream.stream_service} />
                            {stream.type && (
                                <div className="card card-body">
                                    {stream.type === "rent" && "Leie"}
                                </div>
                            )}

                            </div>
                        </div>
                    )
                })}

            </div>
            )}
        </React.Fragment>
    )
}
export default MovieStreamServices