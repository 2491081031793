import React, { useState, useContext } from 'react'
import { auth } from '../../../../firebase';
import DispatchContext from '../../../../DispatchContext';

function LoginForm() {

    
    const appDispatch = useContext(DispatchContext)

    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    async function loginHandler(e) {
        e.preventDefault()
        try {
            const credential = await auth.signInWithEmailAndPassword(email, password);
            appDispatch({type: "login", value: credential.user.uid})
            setError("")
            setMessage("Du er logget inn!")
        } catch (error) {
            setError("Kunne ikke logge inn, prøv igjen")
            setMessage("")
        }
    }

    return (
        <form onSubmit={loginHandler}>

            <div class="md-form">
                {email && <><label for="form1"><small>E-post</small></label><br /><br/></>}
                <input autoComplete={false} type="email" id="form1" className="form-control text-white bgLight" onChange={e => setEmail(e.target.value)} />
                {!email && <label for="form1">E-post</label>}
            </div>
            <div class="md-form">
                {password && <><label for="form1"><small>Passord</small></label><br /><br/></>}
                <input type="password" id="form1" className="form-control text-white" onChange={e => setPassword(e.target.value)} />
                {!password && <label for="form1">Passord</label>}
            </div>

            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <button className="btn btn-danger btn-block mt-3">Logg inn</button>

            
        </form>
    )
}
export default LoginForm