import React, { useEffect } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useKeyPress } from '../../../../hooks/useKeyPress'

function PreviewPlayButton({movie}) {

	const play = useKeyPress("Enter")
	const history = useHistory()

	useEffect(() => {
		if (play) {
			playMovie()
		}
	}, [play])

	function playMovie() {
		console.log("PLAY MOVIE")
		history.push(`/video/${movie?.id}`)
	}

	return (
		<button>
			<i className="fas fa-play"></i>
			<Link className="text-white" to={`/video/${movie.id}`}> Spill av</Link>
		</button>
	)
}
export default PreviewPlayButton
