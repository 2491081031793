import React, { useState, useEffect } from 'react'
import { firestore } from '../../../../firebase'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function MoviesStreamService({stream}) {

    const IMAGE_URL = "https://image.tmdb.org/t/p/w1280"
    const [movies, setMovies] = useState([])

    useEffect(() => {

        try {
            const moviesRef = firestore.collection("movies-streaming");
            moviesRef.where("stream_service_id", "==", stream.id).limit(30).onSnapshot(({docs}) => {
                const movies = docs.map(doc => {
                    return {id: doc.id, ...doc.data()}
                })
                setMovies(movies)
            })
        } catch (error) {
            
        }

    }, [stream])

    return (
        <div className="category">
            <h4 className="font-weight-bold"><Link to={`/streamservice/${stream.id}`}><img className="img-small rounded" src={stream.image} alt={stream.name} /> </Link></h4>
            <div className="scrollmenu">
                
                {movies && movies.map((movie, index) => {
                    return (
                        <Link key={index} to={`/movies/single/${movie.tmdb_id}`}>
                            <motion.div  
                                whileHover={{ scale: 1.2 }}
                                whileTap={{ scale: 0.8 }}
                            >
                                <img className="img-fluid genre_item" src={`${IMAGE_URL}/${movie.movie_poster}`} alt={movie.movie_title} />
                            </motion.div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}
export default MoviesStreamService