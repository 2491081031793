import React, { useContext, useState } from 'react'

import { firestore } from '../../firebase'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StateContext from '../../StateContext';

function AddPost() {

    const appState = useContext(StateContext)

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [image, setImage] = useState("")

    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    
      const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ]
      }

      const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ]

    async function submitHandler(e) {
        e.preventDefault()

        try {
            const postRef = firestore.collection("movie-blog")
            const data = {
                title, 
                content,
                created_date: new Date().toISOString(),
                featured_image: image,
                author_id: appState.userId

            }
            postRef.add(data)
            setMessage("Lagt til!")
        } catch (error) {
            console.log(error)
            setError("Kunne ikke legge til")
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <label>Tittel</label>
            <input className="form-control mb-2" value={title} onChange={e => setTitle(e.target.value)} placeholder="Tittel" />
            
            <label>Fremhevet bilde url</label>
            <input className="form-control mb-2" value={image} onChange={e => setImage(e.target.value)} placeholder="Fremhevet bilde" />
    
            <label>Innhold</label>
            <ReactQuill modules={modules} formats={formats} theme="snow" value={content} onChange={setContent} />
            
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <button className="btn btn-block btn-primary">Legg til</button>
        </form>
    )
}
export default AddPost
