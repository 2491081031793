import React, { useContext, useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { auth } from '../../../firebase'

import StateContext from '../../../StateContext'
import NavbarDark from './Templates/NavbarDark'
import DispatchContext from '../../../DispatchContext'

function UserPage() {
    const appState = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)

    const [activePage, setActivePage] = useState('')

    useEffect(() => {
        setActivePage('user')
    }, [])

    function logoutHandler() {
        try {
            auth.signOut()
            appDispatch({ type: 'logout' })
        } catch (error) {}
    }

    if (!appState.loggedIn) return <Redirect to="/login" />

    return (
        <div style={{ minHeight: '70vh' }}>
            <NavbarDark activePage={activePage} color="elegant-color-dark" />
            <div className="py-5 container">
                <h4 className="my-3">Konto</h4>
                <hr />
                <Link to="/user/my-list">Min liste</Link>
                <button
                    onClick={logoutHandler}
                    className="btn btn-danger btn-block"
                >
                    Logg ut
                </button>
            </div>
        </div>
    )
}
export default UserPage
