import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { firestore } from '../../firebase';
import { dateInputFormatter } from '../../helpers/dateFormatter';
import StateContext from '../../StateContext';

function EditPost({id}) {

    const appState = useContext(StateContext)

    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [image, setImage] = useState("")
    const [date, setDate] = useState("")

    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    useEffect(() => {

        const dateInputFormat = "2020-11-15"
        
        try {
            const postRef = firestore.collection("movie-blog").doc(id);
            postRef.onSnapshot(doc => {
                const post = {id: doc.id, ...doc.data()}
                setTitle(post.title)
                setContent(post.content)
                setImage(post.featured_image)

                if (post.public_date) {
                    const inputDate = dateInputFormatter(post.public_date)
                    setDate(inputDate)
                } else {
                    setDate(dateInputFormat)
                }

                
            })
        } catch (error) {
            console.log(error)
        }
    }, [id])
    
    const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }
 
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]



    async function submitHandler(e) {
        e.preventDefault()
        console.log(new Date(date).toISOString())
        try {
            const postRef = firestore.collection("movie-blog").doc(id)
            const data = {
                title,
                content,
                featured_image: image,
                edited_by: appState.userId,
                edit_date: new Date().toISOString(),
                public_date: new Date(date).toISOString()
            }
            postRef.update(data)
            setMessage("Oppdatert")
            
        } catch (error) {
            setError("Kunne ikke oppdatere")
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <label>Tittel</label>
            <input className="form-control mb-2" value={title} onChange={e => setTitle(e.target.value)} />
            
            <label>Fremhevet bilde url</label>
            <input className="form-control mb-2" value={image} onChange={e => setImage(e.target.value)} />

            <label>Visningsdate</label>
            <input type="date" className="form-control mb-2" value={date} onChange={e => setDate(e.target.value)} />

            <label>Innhold</label>
            <ReactQuill modules={modules} formats={formats} theme="snow" value={content} onChange={setContent} />
            
            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <button className="btn btn-block btn-primary">Legg til</button>
        </form>
    )
} 
export default EditPost
