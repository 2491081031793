import React, { useState, useContext, useEffect } from 'react'
import StateContext from '../../../../StateContext'
import { Link } from 'react-router-dom'
import { firestore } from '../../../../firebase'
import { motion } from 'framer-motion'

function UserMoviesScroll() {

    const appState = useContext(StateContext)

    const IMAGE_URL = "https://image.tmdb.org/t/p/w1280"
    const [movies, setMovies] = useState([])

    useEffect(() => {

        async function fetchUserMovies() {
            if (appState.userId) {
                try {
                    const userMoviesRef = firestore.collection("users").doc(appState.userId).collection("movies");
                    userMoviesRef.onSnapshot(({docs}) => {
                        const movies = docs.map((doc) => {
                            
                            return {id: doc.id, ...doc.data()}
                        })
                        console.log(movies, "MOVIES")
                        setMovies(movies)
                    })
                } catch (error) {
                    console.log(error)
                }
            }


        }
        fetchUserMovies()

    }, [appState.userId])


    return (
        <div className="category mt-4">
            <h4 className="text-white font-weight-bold"><Link className="text-white" to={`/user/my-list`}>Min liste</Link></h4>
            <div className="scrollmenu">
                {movies && movies.map((movie, index) => {
                    return (
                        <Link key={index} to={`/movies/single/${movie.movieId}`}>
                            <motion.div  
                                whileHover={{ scale: 1.2 }}
                                whileTap={{ scale: 0.8 }}
                            >
                                <img className="img-fluid genre_item" src={IMAGE_URL + movie.moviePoster} alt={movie.title} />
                            </motion.div>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}
export default UserMoviesScroll