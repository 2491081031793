import React, { useEffect, useState } from 'react'
import { getRecommendedMovies } from '../../../../api/api_tmdb'
import MovieScrollListTemplate from './MovieScrollListTemplate';

function RecommendedMovies({movieId}) {
    const [movies, setMovies] = useState([])
    useEffect(() => {

        async function getMovies() {
            const data = await getRecommendedMovies(movieId);
            setMovies(data)
            console.log(data)
        }
        getMovies()

    }, [movieId])

    return (
        <div className="py-2">
            {movies?.length > 0 && <MovieScrollListTemplate movies={movies} />}
        </div>
    )
}
export default RecommendedMovies