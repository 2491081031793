import { motion } from 'framer-motion'
import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from 'mdbreact'
import React, { useEffect, useRef, useState } from 'react'
import { getGenres, getTvGenres } from '../api/api_tmdb'
import ScrollGrid from '../components/Carousel/ScrollGrid'
import NavbarDark from '../components/FilmApp/Netflix/Templates/NavbarDark'
import { addToCache, getCacheByKey } from '../helpers/cache'
import { useKeyPress } from '../hooks/useKeyPress'

function ScrollGridPage() {
    const [genres, setGenres] = useState([])
    const [tvGenres, setTvGenres] = useState([])

    const [genresToShow, setGenresToShow] = useState([])
    const [tvGenresToShow, setTvGenresToShow] = useState([])
    const [showModal, setShowModal] = useState(false)

    const [activeRow, setActiveRow] = useState(1)
    const [backgroundImage, setBackgroundImage] = useState(
        'https://image.tmdb.org/t/p/w1280/nMfH0LMMFk2ovyURkYvyTI99nSo.jpg'
    )
    const [perPage, setPerPage] = useState(12)
    const [scroll, setScroll] = useState(false)
    const scrollGridRef = useRef(null)

    const [type, setType] = useState('movie')

    const down = useKeyPress('s')
    const downArrow = useKeyPress('ArrowDown')
    const up = useKeyPress('w')
    const upArrow = useKeyPress('ArrowUp')
    const help = useKeyPress('h')
    const pageOpts = [4, 6, 12]

    function toggleModal() {
        setShowModal((showModal) => !showModal)
    }

    useEffect(() => {
        const showModalStatusLS = getCacheByKey('hideModalInteractive')
        if (!showModalStatusLS) {
            setShowModal(true)
            addToCache('hideModalInteractive', true)
        }
        console.log('ADD TO CACHE HIDE MODAL')
    }, [])

    useEffect(() => {
        window.addEventListener(
            'keydown',
            (e) => {
                console.log(e.key)
                // space and arrow keys
                if ([32, 37, 38, 39, 40].indexOf(e.code) > -1) {
                    e.preventDefault()
                }
            },
            false
        )
    }, [])

    useEffect(() => {
        if (tvGenres) {
            setTvGenresToShow(tvGenres)
        }
        if (genres) {
            setGenresToShow(genres)
        }
    }, [tvGenres, genres])

    useEffect(() => {
        async function getGenresScroll() {
            const movieGenresLS = getCacheByKey('genresTMDB')
            const tvGenresLS = getCacheByKey('genresTvTMDB')
            if (movieGenresLS?.length > 0) {
                console.log('GETTING GENRES LS MOVIE', movieGenresLS)
                setGenres(movieGenresLS)
            } else {
                const data = await getGenres()
                if (data?.length > 0) {
                    setGenres(data)
                    addToCache('genresTMDB', data)
                }
            }
            if (tvGenresLS?.length > 0) {
                console.log('GETTING GENRES LS TV', tvGenresLS)
                setTvGenres(tvGenresLS)
            } else {
                const data = await getTvGenres()
                if (data?.length > 0) {
                    setTvGenres(data)
                    addToCache('genresTvTMDB', data)
                }
            }
        }
        getGenresScroll()
    }, [type])

    useEffect(() => {
        let totalGenres = 0
        if (type === 'tv') {
            totalGenres = tvGenres?.length
        } else {
            totalGenres = genres?.length
        }
        if (up || upArrow) {
            if (activeRow > 1) {
                setActiveRow(activeRow - 1)
            } else {
                setActiveRow(totalGenres)
            }
        }
        if (down || downArrow) {
            if (activeRow >= totalGenres) {
                setActiveRow(1)
                window.scrollTo(0, 0)
            } else {
                setActiveRow(activeRow + 1)
            }
            if (downArrow) {
                window.scrollTo(0, 0)
            }
        }
        if (help) {
            toggleModal()
        }
    }, [help, up, upArrow, down, downArrow, genres, tvGenres, type])

    useEffect(() => {
        if (type === 'movie') {
            if (activeRow >= genres?.length - 1) {
                const genresToShow = genres?.slice(0, 2)
                const newGenres = genres?.concat(genresToShow)
                setGenresToShow(newGenres)
            }
            if (activeRow > 1 && activeRow < genres?.length) {
                const genresToShow = genres?.slice(0, 2)
                const newGenres = genres?.concat(genresToShow)
                //setGenresToShow()
            }
        }
        if (type === 'tv') {
            if (activeRow >= tvGenres?.length - 1) {
                const genresToShow = tvGenres?.slice(0, 2)
                const newGenres = tvGenres?.concat(genresToShow)
                setTvGenresToShow(newGenres)
            }
            if (activeRow > 1 && activeRow < tvGenres?.length) {
                const genresToShow = tvGenres?.slice(0, 2)
                const newGenres = tvGenres?.concat(genresToShow)
                //setGenresToShow()
            }
        }
        /* if (activeRow === 1) {
            window.scrollTo(0, 0)
        } else {
            if (scrollGridRef?.current?.offsetHeight) {
                const totalHeight =
                    scrollGridRef.current.offsetHeight * activeRow -
                    scrollGridRef.current.offsetHeight
                window.scrollTo(0, totalHeight)
            }
        } */
    }, [scrollGridRef, activeRow, genres, type])

    function toggleScroll() {
        if (scroll) {
            setScroll(false)
        } else {
            setScroll(true)
        }
    }

    return (
        <div
            style={{
                backgroundImage: `url('${backgroundImage}')`,
                backgroundRepeat: 'none',
                backgroundSize: 'cover',
                overflowX: 'hidden',
                zIndex: '999',
            }}
        >
            <div
                style={{
                    backgroundColor: showModal ? '' : 'rgba(0,0,0,0.8)',
                    paddingBottom: '20vh',
                }}
            >
                <NavbarDark color="elegant-color-dark" />

                <div style={{ paddingTop: '4rem' }}>
                    <div className="px-4">
                        {/* <div>
                        <label className="switch">
                            <input type="checkbox" onClick={toggleScroll} />
                            <span className="slider round"></span>
                        </label>
                        <span className="text-white">
                            Scroll {scroll ? 'on' : 'off'}
                        </span>
                    </div> */}
                        {/* <Transition /> */}
                        <div className="row">
                            <div className="col-6">
                                <button
                                    className={`netflix-button ${
                                        type === 'movie'
                                            ? 'netflix-button-selected'
                                            : ''
                                    } btn-block`}
                                    onClick={() => setType('movie')}
                                >
                                    Show movies
                                </button>
                            </div>
                            <div className="col-6">
                                <button
                                    className={`netflix-button ${
                                        type === 'tv'
                                            ? 'netflix-button-selected'
                                            : ''
                                    } btn-block`}
                                    onClick={() => setType('tv')}
                                >
                                    Show tv
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            {pageOpts.map((pageOpt, index) => {
                                return (
                                    <div className="col-4" key={index}>
                                        <button
                                            className={`netflix-button ${
                                                perPage === pageOpt
                                                    ? 'netflix-button-selected'
                                                    : ''
                                            } btn-block`}
                                            onClick={() => setPerPage(pageOpt)}
                                        >
                                            Per page: {pageOpt}
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <React.Fragment>
                        {type === 'movie' && (
                            <motion.div
                                initial={{ x: '-100vw' }}
                                animate={{ x: 0 }}
                                transition={{ duration: 1 }}
                            >
                                {(type, genres?.length)}
                                {genresToShow?.map((genre, index) => {
                                    let displayStatus = 'block'
                                    if (
                                        index > activeRow + 1 ||
                                        index < activeRow - 1
                                    ) {
                                        displayStatus = 'none'
                                    }
                                    return (
                                        <motion.div
                                            initial={{ opacity: 0.3 }}
                                            animate={{ opacity: 1 }}
                                            transition={{
                                                delay: index > 2 ? 1 : index,
                                                duration: 0.6,
                                            }}
                                            key={index}
                                            style={{ display: displayStatus }}
                                        >
                                            <ScrollGrid
                                                scrollGridRef={scrollGridRef}
                                                setBackgroundImage={
                                                    setBackgroundImage
                                                }
                                                genre={genre}
                                                genreId={genre.id}
                                                isActive={
                                                    activeRow === index + 1
                                                }
                                                perPage={perPage}
                                                type={'movie'}
                                            />
                                        </motion.div>
                                    )
                                })}
                            </motion.div>
                        )}
                    </React.Fragment>
                    <React.Fragment>
                        {type === 'tv' && (
                            <React.Fragment>
                                {tvGenresToShow?.map((genre, index) => {
                                    let displayStatus = 'block'
                                    if (
                                        index > activeRow + 1 ||
                                        index < activeRow - 1
                                    ) {
                                        displayStatus = 'none'
                                    }
                                    return (
                                        <div
                                            style={{ display: displayStatus }}
                                            key={index}
                                        >
                                            <ScrollGrid
                                                scrollGridRef={scrollGridRef}
                                                setBackgroundImage={
                                                    setBackgroundImage
                                                }
                                                genre={genre}
                                                genreId={genre.id}
                                                isActive={
                                                    activeRow === index + 1
                                                }
                                                perPage={perPage}
                                                type={'tv'}
                                            />
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </div>
            </div>

            <MDBModal
                toggle={toggleModal}
                isOpen={showModal}
                backgroundColor="dark"
            >
                <MDBModalHeader>
                    <strong>Interaktiv hjelper</strong>
                </MDBModalHeader>
                <MDBModalBody>
                    <h4 className="font-weight-bold mb-3">Navigering</h4>
                    <p>
                        Opp:{' '}
                        <span className="font-weight-bold float-right">
                            PIL OPP / W
                        </span>
                    </p>
                    <p>
                        Ned:{' '}
                        <span className="font-weight-bold float-right">
                            PIL NED / S
                        </span>
                    </p>
                    <p>
                        Venstre:{' '}
                        <span className="font-weight-bold float-right">
                            PIL VENSTRE / A
                        </span>
                    </p>
                    <p>
                        Høyre:{' '}
                        <span className="font-weight-bold float-right">
                            PIL HØYRE / D
                        </span>
                    </p>
                    <p>
                        Lukk popup:{' '}
                        <span className="font-weight-bold float-right">
                            ESC
                        </span>
                    </p>

                    <p className="font-weight-bold">
                        For å vise popup igjen, trykk{' '}
                        <span className="text-success">H</span>
                    </p>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={toggleModal}>
                        Close
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>
    )
}
export default ScrollGridPage
