import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { firestore } from '../../firebase'
import StateContext from '../../StateContext';
import NavbarDark from '../FilmApp/Netflix/Templates/NavbarDark';
import Page from '../ReusableComponents/Page';

function SinglePost({id}) {

    const appState = useContext(StateContext)

    const [post, setPost] = useState();

    useEffect(() => {
        if (id) {
            console.log(id, "ID")
            try {
                const postRef = firestore.collection("movie-blog").doc(id);
                postRef.onSnapshot(doc => {
                    const post = {id: doc.id, ...doc.data()}
                    setPost(post)
                })
            } catch (error) {
                console.log(error)
            }
        }


    }, [id])

    return (
        <Page pageTitle={post?.title || "Post"}>
            <NavbarDark activePage="Post" color="elegant-color-dark" />
            <br />
            <div className="container py-5">
                {appState.loggedIn && <Link to={`/posts/${id}/edit`}>Rediger</Link>}
                <h2 className="my-3">{post?.title}</h2>
                <div dangerouslySetInnerHTML={{__html: post?.content}}></div>
            </div>
        </Page>
    )
}
export default SinglePost