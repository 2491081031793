import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { useImmerReducer } from 'use-immer'

import StateContext from './StateContext'
import DispatchContext from './DispatchContext'

import './index.css'
import Home from './pages/Home'
import Footer from './components/Footer/Footer'

// MOVIE APP
import SearchMovies from './components/FilmApp/Search/SearchMovies'
import SingleMovie from './components/FilmApp/Netflix/SingleMovie'
import TV from './components/FilmApp/Netflix/TV'
import SingleTV from './components/FilmApp/Netflix/SingleTV'
import SearchShows from './components/FilmApp/Search/SearchShows'
// SPILL
import SingleGenrePage from './components/FilmApp/Netflix/SingleGenrePage'
import LoginPage from './components/FilmApp/Netflix/LoginPage'
import MoviesStreamServicePage from './components/FilmApp/Netflix/MoviesStreamServicePage'
import { auth } from './firebase'
import MyList from './components/FilmApp/Netflix/MyList'
import UserPage from './components/FilmApp/Netflix/UserPage'
import PostsPage from './pages/PostsPage'
import SinglePost from './components/Blog/SinglePost'
import EditPostPage from './pages/EditPostPage'
import SinglePostPage from './pages/SinglePostPage'
import SinglePostWpPage from './pages/SinglePostWpPage'
import ScrollGridPage from './pages/ScrollGridPage'
import ScrollGridFullPage from './pages/ScrollGridFullPage'
import TestPage from './pages/TestPage'
import VideoPage from './pages/VideoPage'

function App() {
    const initialState = {
        loggedIn: false,
        userId: '',
        genreId: 28
    }

    function ourReducer(draft, action) {
        switch (action.type) {
            case 'login':
                draft.loggedIn = true
                draft.userId = action.value
                return
            case 'logout':
                draft.loggedIn = false
                return
            case 'genreChanged':
                draft.genreId = action.value
                return
        }
    }

    const [state, dispatch] = useImmerReducer(ourReducer, initialState)

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            console.log(user.uid, 'USER')
            if (user) {
                dispatch({ type: 'login', value: user.uid })
            }
        })
    }, [])

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact>
                            <Home />
                        </Route>

                        <Route path="/streamservice/:id">
                            <MoviesStreamServicePage />
                        </Route>
                        <Route path="/movies/genre/:genreId" exact>
                            <SingleGenrePage />
                        </Route>
                        <Route path="/search-movies" exact>
                            <SearchMovies />
                        </Route>
                        <Route path="/movies/single/:id" exact>
                            <SingleMovie />
                        </Route>
                        <Route path="/shows/:page" exact>
                            <TV />
                        </Route>
                        <Route path="/search-shows" exact>
                            <SearchShows />
                        </Route>
                        <Route path="/shows/single/:id" exact>
                            <SingleTV />
                        </Route>

                        <Route path="/login" exact>
                            <LoginPage />
                        </Route>
                        <Route path="/user" exact>
                            <UserPage />
                        </Route>
                        <Route path="/user/my-list" exact>
                            <MyList />
                        </Route>

                        <Route path="/posts" exact>
                            <PostsPage />
                        </Route>

                        <Route path="/posts/:id" exact>
                            <SinglePostPage />
                        </Route>

                        <Route path="/posts-wp/:id" exact>
                            <SinglePostWpPage />
                        </Route>

                        <Route path="/posts/:id/edit" exact>
                            <EditPostPage />
                        </Route>
                        <Route path="/scrollgrid" exact>
                            <ScrollGridPage />
                        </Route>
                        <Route path="/scrollgridfull" exact>
                            <ScrollGridFullPage />
                        </Route>
                        <Route path="/video/:id" exact>
                            <VideoPage />
                        </Route>
                        <Route path="/test" exact>
                            <TestPage />
                        </Route>
                    </Switch>
                    <Footer />
                </BrowserRouter>
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

export default App
