import React from 'react'
import { motion, AnimatePresence } from "framer-motion";
const Accordion = ({ i, expanded, setExpanded }) => {
    const isOpen = i === expanded;
  
    // By using `AnimatePresence` to mount and unmount the contents, we can animate
    // them in and out while also only rendering the contents of open accordions
    return (
      <>
        <motion.header
          initial={false}
          animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
        <div className={`card card-header text-white ${isOpen ? "bg-danger" : "bg-primary"}`}>
            
            <div>
            Accordion {i}
                <span className="float-right"><i className={`fas fa-chevron-${isOpen ? "up" : "down"}`}></i></span>
            </div>
        </div>    
        </motion.header>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 }
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <img src="https://image.tmdb.org/t/p/w1280/nMfH0LMMFk2ovyURkYvyTI99nSo.jpg" />
            </motion.section>
          )}
        </AnimatePresence>
      </>
    );
  };
export default Accordion