import React, { useEffect, useState } from 'react'
import { config } from '../../../config/config'

function ScrollGridLazyTemplate({movies, activeItem, cols, isActive, perRow}) {

    const [moviesToShow, setMoviesToShow] = useState(movies)

    useEffect(() => {

        const currentRow = Math.ceil(activeItem / perRow)
        console.log(currentRow, "CURRENT ROW")

        if (activeItem > (perRow * 2)) {
            // SLICE TOP ROW EACH TIME WE GO DOWN

            

            const slicedMovies = movies?.slice(perRow * (currentRow-2), movies?.length)
            setMoviesToShow(slicedMovies)
            console.log(slicedMovies?.length, "TOTAL MOVIES")
        } else {
            setMoviesToShow(movies?.slice(0, perRow * 3))
        }

    }, [movies, activeItem])

    function isSelected(movieId, movies, activeItem) {
        const selectedMovie = movies[activeItem - 1]
        return selectedMovie?.id === movieId
    }

    return (
        <div className="row no-gutters">
            {moviesToShow?.map((movie, index) => {
                return (
                    <div className={`col-${cols} p-1`} key={index}>
                        <img src={config.api_image_base_url + movie?.poster_path} className={isActive && isSelected(movie?.id, movies, activeItem) ? "selected" : ""} alt="" />
                    </div>
                )
            })}
        </div>
    )
}
export default ScrollGridLazyTemplate