import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import { config } from '../../../../config/config'

function MovieScrollListTemplate({movies}) {
    return (
        <motion.div
            animate={{ scale: 1 }}
            initial={{scale: 0.8}}
            transition={{ duration: 0.5 }}
        >
            <div className="category">
                <div className="scrollmenu">
                    {movies?.map((movie, index) => {
                        return (
                            
                                <Link to={`/movies/single/${movie.id}`} key={index}>
                                    <motion.div  
                                        whileHover={{ scale: 1.2 }}
                                        whileTap={{ scale: 0.8 }}
                                    >
                                        <img className="img-fluid genre_item" src={`${config.api_image_base_url}/${movie.poster_path}`} alt={movie.title} />
                                    </motion.div>
                                </Link>
                            
                        )
                    })}
                </div>
            </div>
        </motion.div>
    )
}
export default MovieScrollListTemplate