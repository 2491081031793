import React, { useContext, useEffect, useState } from 'react'
import StateContext from '../../../../StateContext';
import { firestore } from '../../../../firebase';
import { getUserMovies } from '../../../../firebase/firebaseRequestHelper';

function WatchListHandler({movie}) {

    const appState = useContext(StateContext)

    const [added, setAdded] = useState(false)
    const [watchList, setWatchList] = useState([])

    useEffect(() => {
        if (appState.loggedIn && appState?.userId && movie?.id) {
            getUserMovies(appState.userId, setAdded, setWatchList, movie.id)
        }


    }, [movie.id, appState.loggedIn])

    async function listHandler(e) {
        if (appState.userId && appState?.userId) {

            e.preventDefault();
            const moviesListRef = firestore.collection("users").doc(appState.userId).collection("movies");
            const createdAt = new Date();
    
            if (!added) {
                try {
                    
                    moviesListRef.add({
                        movieId: movie.id,
                        moviePoster: movie.poster_path,
                        createdAt
                    })
                    setAdded(true)
                } catch (error) {
                    setAdded(false)
                }
            } else {
                try {
                    const singleMovieRef = firestore.collection("users").doc(appState.userId).collection("movies").doc(watchList[0].id);
                    singleMovieRef.delete()
                    setAdded(false)
                } catch (error) {
                    console.log(error)
                }
            }
        }


    }

    return (
        <button onClick={listHandler}>
            <i className={added ? "fas fa-check" : "fas fa-plus"}></i> Min liste
        </button>
    )
}
export default WatchListHandler