import React from 'react'
import { Link, useParams } from 'react-router-dom'
import EditPost from '../components/Blog/EditPost'
import NavbarDark from '../components/FilmApp/Netflix/Templates/NavbarDark'

function EditPostPage() {

    const {id} = useParams()

    return (
        <div>
            <NavbarDark color="elegant-color-dark" />
            <br />
            <br />
            <div className="container my-3">
                <Link to={`/posts/${id}`}>Vis innlegg</Link>
                <EditPost id={id} />
            </div>      
        </div>
    )
}
export default EditPostPage