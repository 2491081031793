import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import LightboxPage from '../../../Lightbox/Lightbox';

function ImagesMovie({movieId, imagesMovie}) {

    const [images, setImages] = useState([])
    const [totalImages, setTotalImages] = useState(16)

    const API_KEY = "7e21606276f58bbdfa39c8cdd8e051f5"
    const IMAGE_URL = "https://image.tmdb.org/t/p/original/"
    const url = `https://api.themoviedb.org/3/movie/${movieId}/images?api_key=${API_KEY}&language=en-US`

    useEffect(() => {

        if (imagesMovie) {
            const allImages = imagesMovie.backdrops.concat(imagesMovie.posters)
            const imagesToRender = allImages.slice(0, totalImages)
            setImages(imagesToRender)
        }

    }, [imagesMovie, totalImages])


    function loadMore() {
        setTotalImages(totalImages+16)
    }

    return (
        <div className="row no-gutters p-1">
            <LightboxPage imageBaseUrl={IMAGE_URL} imagesArray={images} />
            <div className="my-4 col-12">
                <button onClick={loadMore} className="btn btn-block btn-lg bg-danger">Last mer</button>
            </div>
            
        </div>
    )

}
export default ImagesMovie


