import * as firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyB35iX9w38I0GBo24sTuwpMxCCc4sq4hqo",
    authDomain: "movie-app-ff667.firebaseapp.com",
    databaseURL: "https://movie-app-ff667.firebaseio.com",
    projectId: "movie-app-ff667",
    storageBucket: "movie-app-ff667.appspot.com",
    messagingSenderId: "234222561369",
    appId: "1:234222561369:web:5f5a0c1b4e2a43bb432948",
    measurementId: "G-60WP1FJQW0"
  };

const app = firebase.initializeApp(firebaseConfig)
export const auth = app.auth();
export const firestore = app.firestore()
export const storage = app.storage()