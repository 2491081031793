import React, { useContext, useEffect, useState } from 'react'
import MovieGenreScrollGridList from '../components/Carousel/MovieGenreScrollGridList'
import ScrollGridFull from '../components/Carousel/ScrollGridFull'
import NavbarDark from '../components/FilmApp/Netflix/Templates/NavbarDark'
import { getCacheByKey } from '../helpers/cache'
import StateContext from '../StateContext'

function ScrollGridFullPage() {

    const {genreId} = useContext(StateContext)
    const [activeColumn, setActiveColumn] = useState(1)

    console.log(activeColumn, "ACTIVE COL")

    return (
        <div style={{backgroundColor: "black"}}>
            <NavbarDark />
            <div className="container-fluid pt-5">
            <div className="pt-5"></div>
            
            <div className="row">
                <div className={activeColumn === 1 ? "col-4" : ""}>
                    <MovieGenreScrollGridList genreId={genreId} activeColumn={activeColumn} setActiveColumn={setActiveColumn} />
                </div>
                <div className={activeColumn === 2 ? "col-12" : "col-8"}>
                    <ScrollGridFull activeColumn={activeColumn} setActiveColumn={setActiveColumn} genreId={genreId} />
                </div>
            </div>
            </div>
        </div>
    )
}
export default ScrollGridFullPage