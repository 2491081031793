import React, { useContext, useState } from 'react'
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavItem,
    MDBNavLink,
    MDBNavbarToggler,
    MDBCollapse,
    MDBContainer,
    MDBDropdownItem,
    MDBDropdownToggle,
    MDBDropdown,
    MDBDropdownMenu,
} from 'mdbreact'
import { Link } from 'react-router-dom'
import StateContext from '../../../../StateContext'
import { config } from '../../../../config/config'

function NavbarDark({ activePage, color }) {
    const [isOpen, setIsOpen] = useState(false)
    const appState = useContext(StateContext)

    const toggleCollapse = () => {
        if (isOpen) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }

    const [background, setBackground] = useState(color ? color : 'transparent')

    window.addEventListener('scroll', () => {
        scrollHandler(window.scrollY)
    })

    function scrollHandler(scrollHeight) {
        if (scrollHeight > 100) {
            setBackground('elegant-color-dark')
        } else {
            if (!color) {
                setBackground('transparent')
            }
        }
    }

    return (
        <MDBNavbar color={background} dark expand="md" fixed>
            <MDBContainer fluid>
                <MDBNavbarBrand>
                    <Link to="/">
                        <img
                            src={config.site_logo}
                            className="img-fluid"
                            style={{ maxWidth: '80px' }}
                            alt=""
                        />
                    </Link>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={toggleCollapse} />
                <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
                    <MDBNavbarNav left>
                        <MDBNavItem active={activePage === 'home'}>
                            <MDBNavLink
                                onClick={() => {
                                    isOpen && setIsOpen(false)
                                }}
                                to="/"
                            >
                                Home
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem active={activePage === 'shows' && true}>
                            <MDBNavLink
                                onClick={() => {
                                    isOpen && setIsOpen(false)
                                }}
                                to="/shows/1"
                            >
                                Serier
                            </MDBNavLink>
                        </MDBNavItem>

                        <MDBNavItem active={activePage === 'movies'}>
                            <MDBNavLink
                                onClick={() => {
                                    isOpen && setIsOpen(false)
                                }}
                                to="/"
                            >
                                Filmer
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem active={activePage === ''}>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                <span className="mr-2">Interaktiv</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                <MDBDropdownItem>
                                    <Link
                                        onClick={() => {
                                            isOpen && setIsOpen(false)
                                        }}
                                        to="/scrollgrid"
                                    >
                                        Interaktiv
                                    </Link>
                                </MDBDropdownItem>
                                <MDBDropdownItem ><Link to="/scrollgridfull">Grid</Link></MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                        </MDBNavItem>
                        {appState.loggedIn && (
                            <MDBNavItem active={activePage === 'mylist'}>
                                <MDBNavLink
                                    onClick={() => {
                                        isOpen && setIsOpen(false)
                                    }}
                                    to="/user/my-list"
                                >
                                    Min Liste
                                </MDBNavLink>
                            </MDBNavItem>
                        )}
                    </MDBNavbarNav>

                    <MDBNavbarNav right>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={() => {
                                    isOpen && setIsOpen(false)
                                }}
                                to="/search-movies"
                            >
                                <i className="fas fa-search"></i>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem active={activePage === 'user'}>
                            <MDBNavLink
                                onClick={() => {
                                    isOpen && setIsOpen(false)
                                }}
                                to="/login"
                            >
                                <i className="fas fa-user"></i>
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    )
}
export default NavbarDark
