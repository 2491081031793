import React, { useContext, useEffect, useState } from 'react'
import { getGenres } from '../../api/api_tmdb';
import DispatchContext from '../../DispatchContext';
import { getCacheByKey } from '../../helpers/cache';
import StateContext from '../../StateContext';
import ScrollGridList from './ScrollGridList';

function MovieGenreScrollGridList({activeColumn, setActiveColumn}) {

    const {genreId} = useContext(StateContext)
    const appDispatch = useContext(DispatchContext)
    const [genres, setGenres] = useState([])

    useEffect(() => {
        async function getGenresList() {
            const genresLS = getCacheByKey("genresTMDB");
            const popular = [{
                id: 0,
                name: "Popular"
            }]
            if (genresLS?.length > 0) {
                
                setGenres(popular.concat(genresLS))
                if (!genreId) {
                    appDispatch({type: 'genreChanged', value: genresLS[0]?.id})
                }
                console.log(genresLS[0].id)
            } else {
                const data = await getGenres()
                if (data?.length > 0) {
                    setGenres(popular.concat(data))
                    if (!genreId) {
                        appDispatch({type: 'genreChanged', value: data[0]?.id})
                    }
                }
            }
        }
        getGenresList()
    }, [])

    return <ScrollGridList genres={genres} activeColumn={activeColumn} setActiveColumn={setActiveColumn} />
}
export default MovieGenreScrollGridList