import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { addToCache, getCacheByKey } from '../../../helpers/cache';
import { daysSinceDate } from '../../../helpers/dateFormatter';
import { config } from '../../../config/config';
import { getMoviesByGenreId } from '../../../api/api_tmdb';
import { motion } from 'framer-motion';
import MovieScrollListTemplate from './Templates/MovieScrollListTemplate';

function SingleGenre({genre}) {

    const IMAGE_URL = config.api_image_base_url
    const [movies, setMovies] = useState([])

    function getCachedMovies() {
        const localMovies = getCacheByKey(`moviesGenre${genre.id}`);
        return localMovies
    }

    async function getMovies() {
        const movies = await getMoviesByGenreId(1, genre.id);
        setMovies(movies)
        const moviesObjCache = {
            date_added: new Date().toISOString(),
            movies: movies
        }
        console.log("SETTING MOVIES TO FETCH", movies)
        addToCache(`moviesGenre${genre.id}`, moviesObjCache);
    }

    useEffect(() => {
        const cachedMovies = getCachedMovies();
        if (cachedMovies) {
            const dateCache = new Date(cachedMovies?.date_added);
            const daysSinceCacheAdded = Math.round(daysSinceDate(dateCache))
            if (cachedMovies?.movies?.length > 0 && daysSinceCacheAdded < config.cache_duration) {
                setMovies(cachedMovies.movies)
            } else {
                getMovies()
            }
            
        } else {
            getMovies()
        }

    }, [genre])

    return (
        <MovieScrollListTemplate movies={movies} />
    )
}
export default SingleGenre