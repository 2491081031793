import React, { useEffect, useRef, useState } from 'react'

function PlayerPc({videoUrl}) {

    const videoRef = useRef(null)
    const [isPaused, setIsPaused] = useState(false)
    const [progress, setProgress] = useState(0)
    const [displayPlayPauseContainer, setDisplayPlayPauseContainer] = useState(false)

    function handlePlay() {
        if (videoRef.current.paused) {
            resumeVideo()
            setDisplayPlayPauseContainer(true)
            setTimeout(() => {
                setDisplayPlayPauseContainer(false)
            }, 5000)
        } else {
            pauseVideo()
        }
    }

    async function resumeVideo() {
        if (videoRef?.current) {
            console.log(await videoRef?.current?.play())
            setIsPaused(false)
        }
    }

    function pauseVideo() {
        console.log(videoRef?.current?.pause())
        videoRef.current.pause()
        setIsPaused(true)
        setDisplayPlayPauseContainer(true)
    }

    useEffect(() => {
        setInterval(() => {
            if (!isPaused && videoRef?.current) {
                setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100)
            }
        }, 100)
    }, [videoRef])

    return (
        <div>
            <div className="watchContainer">
                <video onClick={handlePlay} ref={videoRef} autoPlay className="mediaPlayer" style={{width: "100vw", height: "100vh"}}>
                    <source src={videoUrl} type="video/mp4" />
                </video>
                {isPaused && <div className="playPauseContainer text-center">
                    <button onClick={handlePlay} className="btn btn-danger px-5 py-4"><i className="fas fa-play fa-5x text-white"></i></button>    
                </div>}
                {!isPaused && displayPlayPauseContainer && <div className="playPauseContainer text-center">
                    <button onClick={handlePlay} className="btn btn-danger px-5 py-4"><i className="fas fa-pause fa-5x text-white"></i></button>    
                </div>}
                {displayPlayPauseContainer && <div className="playerProgressContainer">
					<div className="playerProgressContainer__content">
						<div className="row no-gutters">
							<div className="col-1" onClick={handlePlay}>
								<span className="mx-auto">
									<i className={`fas fa-${isPaused ? "play" : "pause"} text-white fa-2x`}></i>
								</span>
							</div>
							<div className="col-10">
								<div className="progressBar mt-3" style={{width: "100%"}}>
									<div className="progressBarContent" style={{width: `${progress}%`, backgroundColor: "red"}}></div>
								</div>
							</div>
							<div className="col-1"></div>
						</div>
						
					</div>
				</div>}
            </div>
        </div>
    )
}
export default PlayerPc