import React, { useState, useEffect, useContext } from 'react'
import { firestore } from '../../../../firebase';
import StateContext from '../../../../StateContext';
import Loader from '../../../Loader/Loader';
import { Link } from 'react-router-dom';

function UserMovies({userId}) {

    const appState = useContext(StateContext)
    const [movies, setMovies] = useState([])
    const IMAGE_URL = "https://image.tmdb.org/t/p/w1280/"
    useEffect(() => {

        async function fetchUserMovies() {
            if (userId) {
                try {
                    const userMoviesRef = firestore.collection("users").doc(appState.userId).collection("movies");
                    userMoviesRef.onSnapshot(({docs}) => {
                        const movies = docs.map((doc) => {
                            
                            return {id: doc.id, ...doc.data()}
                        })
                        console.log(movies, "MOVIES")
                        setMovies(movies)
                    })
                } catch (error) {
                    console.log(error)
                }
            }


        }
        fetchUserMovies()

    }, [userId])

    if (!movies) return <Loader />

    return (
        <div className="row no-gutters py-5">
            {movies && movies.map((movie, index) => {
                return (
                    <div key={index} className="col-4 col-md-3 col-lg-2 pr-1 pt-1" >
                        <div className="card">
                            <Link to={`/movies/single/${movie.movieId}`}>
                                <img src={IMAGE_URL + movie.moviePoster} alt={movie.moviePoster} className="img-fluid" />
                            </Link>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default UserMovies