import { MDBModal } from 'mdbreact'
import React, { useEffect, useRef, useState } from 'react'
import {
    getMoviesByGenreId,
    getPopularMovies,
    getTvByGenreId,
} from '../../api/api_tmdb'
import { config } from '../../config/config'
import { addToCache, getCacheByKey } from '../../helpers/cache'
import { useKeyPress } from '../../hooks/useKeyPress'
import { keyPressHandlerReturnActiveItem } from '../../keyPress/keyPressHandler'
import './ScrollGrid.css'

import './ScrollGridToggle.css'
import ScrollGridModalContent from './ScrollGridModal'
import { getItemsToShowByPosition } from '../../handlers/scrollGridHandler'
import AnimatedImage from './templates/AnimatedImage'
function ScrollGrid({
    genre,
    genreId,
    isActive,
    scrollGridRef,
    perPage,
    scrollEnabled,
    type,
    setBackgroundImage,
}) {
    // Call our hook for each key that we'd like to monitor
    const back = useKeyPress('b')
    const esc = useKeyPress('Escape')
    const enter = useKeyPress('Enter')
    const right = useKeyPress('d')
    const rightArrow = useKeyPress('ArrowRight')
    const leftArrow = useKeyPress('ArrowLeft')
    const left = useKeyPress('a')

    const [key, setKey] = useState("")

    const [activeItem, setActiveItem] = useState(1)
    const [activeSlicedItem, setActiveSlicedItem] = useState(1)

    const cachedMovies = getCacheByKey(`${type || 'movie'}sGenre${genreId}`)
    const [movies, setMovies] = useState([])
    const [moviePosters, setMoviePosters] = useState([])
    const [moviesToShow, setMoviesToShow] = useState([])
    const [page, setPage] = useState(1)
    const [showModal, setShowModal] = useState(false)
    const PER_PAGE = perPage
    const COLS = 12 / PER_PAGE
    const [position, setPosition] = useState({
        startPos: 1,
        endPos: PER_PAGE,
    })

    const movieRef = useRef(null)

    function toggleModal() {
        if (showModal) {
            setShowModal(false)
        } else {
            setShowModal(true)
        }
    }

    useEffect(() => {
        window.addEventListener("keyup", (e) => {
            setKey(e.keyCode)
        })
    }, [])

    useEffect(() => {
        const moviesSliced = movies?.slice(1, PER_PAGE)
        setMoviesToShow(moviesSliced)

        const moviePostersMapped = movies?.map((movie) => {
            let image
            if (movie?.poster_path) {
                image = config.api_image_base_url + movie.poster_path
            } else {
                image = config.default_movie_poster
            }
            return image
        })
        setMoviePosters(moviePostersMapped)
    }, [movies])

    useEffect(() => {
        async function getMovies() {
            let newMovies = []
            if (genreId) {
                if (type === 'tv') {
                    console.log('tv')
                    if (
                        cachedMovies?.movies?.length > 0 &&
                        cachedMovies?.movies?.length === page * 20
                    ) {
                        newMovies = cachedMovies.movies
                    } else {
                        newMovies = await getTvByGenreId(page, genreId)
                        const moviesLS = {
                            date_added: new Date().toISOString(),
                            movies: movies.concat(newMovies),
                        }
                        addToCache(`tvsGenre${genreId}`, moviesLS)
                    }
                } else {
                    if (
                        cachedMovies?.movies?.length > 0 &&
                        cachedMovies?.movies?.length === page * 20
                    ) {
                        newMovies = cachedMovies.movies
                    } else {
                        newMovies = await getMoviesByGenreId(page, genreId)

                        if (page > 1) {
                            const moviesLS = {
                                date_added: new Date().toISOString(),
                                movies: movies.concat(newMovies),
                            }
                            addToCache(`moviesGenre${genreId}`, moviesLS)
                        } else {
                            const moviesLS = {
                                date_added: new Date().toISOString(),
                                movies: newMovies,
                            }
                            addToCache(`moviesGenre${genreId}`, moviesLS)
                        }
                    }
                }
            } else {
                newMovies = await getPopularMovies()
            }
            setMovies((movies) => movies.concat(newMovies))
            const movieSlice = movies?.slice(1, PER_PAGE - 1)
            if (movieSlice?.length > 0) {
                setMoviesToShow(movieSlice)
            } else {
                setMoviesToShow(movies.slice(1, PER_PAGE - 1))
            }
        }
        getMovies()
    }, [genreId, type, page])

    function getMoviesToShowByPosition(startPos, endPos, movies) {
        let newMoviesToShow = getItemsToShowByPosition(
            movies,
            activeItem + 1,
            PER_PAGE - 1,
            scrollEnabled
        )

        return newMoviesToShow
    }

    useEffect(() => {
        if (isActive) {
            if (left || leftArrow) {
                const activeItemFunc = keyPressHandlerReturnActiveItem(
                    'left',
                    movies.length,
                    activeItem
                )
                setActiveItem(activeItemFunc)

                if (activeItem <= 1) {
                    setPosition({
                        startPos: movies?.length - PER_PAGE,
                        endPos: movies?.length,
                    })
                } else {
                    if (activeItem > PER_PAGE) {
                        setPosition({
                            startPos: position.startPos - 1,
                            endPos: activeItem,
                        })
                    } else {
                        if (position.startPos <= 0) {
                            setPosition({
                                startPos: movies?.length - PER_PAGE,
                                endPos: movies?.length,
                            })
                        } else {
                            setPosition({
                                startPos: position.startPos - 1,
                                endPos: position.endPos - 1,
                            })
                        }
                    }
                }
            }
            if (right || rightArrow) {
                const activeItemFunc = keyPressHandlerReturnActiveItem(
                    'right',
                    movies.length,
                    activeItem
                )
                setActiveItem(activeItemFunc)
                // 12 >= (20 - 12)
                if (activeItemFunc >= movies?.length - 13) {
                    //setPage((page) => page + 1)
                } else {
                }

                if (activeItem > movies.length - 1) {
                    setPosition({
                        startPos: 0,
                        endPos: PER_PAGE,
                    })
                } else {
                    if (position.endPos >= movies.length) {
                        setPosition({
                            startPos: 0,
                            endPos: PER_PAGE,
                        })
                    } else {
                        setPosition({
                            startPos: position.startPos + 1,
                            endPos: position.endPos + 1,
                        })
                    }
                }
            }

            if (enter || key === 13) {
                setShowModal(true)
            }
            if (back || key === 8 || esc) {
                setShowModal(false)
            }
        }
    }, [right, rightArrow, left, leftArrow, enter, back, esc, isActive, movies, key])

    useEffect(() => {
        const { startPos, endPos } = position
        const moviesArraySliced = getMoviesToShowByPosition(
            startPos,
            endPos,
            movies
        )
        setMoviesToShow(moviesArraySliced)
    }, [position, isActive, activeItem, movies])

    useEffect(() => {
        if (movies?.length > 0) {
            const currentMovie = movies[activeItem - 1]
            const currentImage = currentMovie.backdrop_path
            if (currentImage) {
                setBackgroundImage(
                    `${config.api_image_base_url}${currentImage}`
                )
            }
        }
    }, [movies, activeItem, isActive])

    return (
        <div ref={scrollGridRef}>
            <h3 className="font-weight-bold text-white px-4 mt-5">
                {genre?.name} KEY: {key}
            </h3>
            {isActive && movieRef?.current?.offsetWidth && (
                <div className="row no-gutters px-4 arrow-container">
                    <div
                        className={`col-${COLS} p-1 selected`}
                        style={{
                            width:
                                movieRef?.current?.offsetWidth + 10 || '100px',
                            height:
                                movieRef?.current?.offsetHeight + 5 || '100px',
                            position: 'absolute',
                            zIndex: '999',
                        }}
                    ></div>
                </div>
            )}
            <div className="row no-gutters px-4 arrow-container">
                <div className={`col-${COLS} p-1`}>
                    <AnimatedImage
                        images={moviePosters}
                        activeItem={activeItem}
                        left={left || leftArrow}
                        right={right || rightArrow}
                        isActive={isActive}
                        movieRef={movieRef}
                    />
                </div>

                {moviesToShow.map((movie, index) => {
                    if (index > PER_PAGE) {
                        return
                    }
                    const isItemSelected =
                        index + 1 === activeSlicedItem && isActive
                    return (
                        <div className={`col-${COLS} p-1`} key={index}>
                            <img
                                src={
                                    movie.poster_path
                                        ? config.api_image_base_url +
                                          movie.poster_path
                                        : config.default_movie_poster
                                }
                                alt=""
                                className={`${
                                    isItemSelected ? '' : activeItem
                                }`}
                            />
                        </div>
                    )
                })}
                <div className="left-arrow text-white">
                    <i className="fas fa-chevron-left"></i>
                </div>
                <div className="right-arrow text-white">
                    <i className="fas fa-chevron-right"></i>
                </div>
            </div>
            <MDBModal size="lg" isOpen={showModal} toggle={toggleModal}>
                <ScrollGridModalContent
                    type={type}
                    movie={movies[activeItem - 1]}
                />
            </MDBModal>
        </div>
    )
}
export default ScrollGrid
