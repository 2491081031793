import Axios from 'axios'
import { config } from '../config/config'

export async function getGenres() {
    let data = []
    try {
        const response = await Axios.get(
            `${config.api_base_url}genre/movie/list?api_key=${config.api_key}`
        )
        if (response?.data?.genres) {
            data = response.data.genres
        }
    } catch (error) {}
    return data
}

export async function getTvGenres() {
    let data = []
    try {
        const response = await Axios.get(
            `${config.api_base_url}genre/tv/list?api_key=${config.api_key}`
        )
        if (response?.data?.genres) {
            data = response.data.genres
        }
    } catch (error) {}
    return data
}

export async function getPopularTvShows(page) {
    let data = []
    try {
        const response = await Axios.get(`https://api.themoviedb.org/3/tv/popular?api_key=${config.api_key}&language=en-US&page=${page}`)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {
        
    }
    return data
}

export async function getMoviesBySearchTerm(searchTerm) {
    let data = []
    try {
        const response = await Axios.get(`https://api.themoviedb.org/3/search/movie?api_key=${config.api_key}&language=en-US&query=${searchTerm}&page=1&include_adult=false`)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {
        
    }
    return data
}

export async function getMoviesByGenreId(page = 1, genreId) {
    let data = []
    try {
        const url = `${config.api_base_url}discover/movie?api_key=${config.api_key}&with_genres=${genreId}&page=${page}`
        const response = await Axios.get(url)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {}
    return data
}

export async function getTvByGenreId(page = 1, genreId) {
    let data = []
    try {
        const url = `${config.api_base_url}discover/tv?api_key=${config.api_key}&with_genres=${genreId}&page=${page}`
        const response = await Axios.get(url)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {}
    return data
}

export async function getSingleMovieById(movieId) {
    let data
    try {
        if (!movieId) {
            console.log("NO MOVIEID")
            return
        }
        const response = await Axios.get(
            `${config.api_base_url}movie/${movieId}?api_key=${config.api_key}&append_to_response=videos,images`
        )
        if (response?.data) {
            data = response.data
        }
    } catch (error) {}
    return data
}

export async function getSimilarMovies(movieId) {
    let data = []
    try {
        const response = await Axios.get(`https://api.themoviedb.org/3/movie/${movieId}/similar?api_key=${config.api_key}&language=en-US&page=1`)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {
        
    }
    return data
}

export async function getRecommendedMovies(movieId) {
    let data = []
    try {
        const response = await Axios.get(`https://api.themoviedb.org/3/movie/${movieId}/recommendations?api_key=${config.api_key}&language=en-US&page=1`)
        console.log(response, "RESPONSE RECOMMENDED")
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {
        
    }
    return data
}

export async function getSingleTvById(movieId) {
    let data
    try {
        const response = await Axios.get(
            `${config.api_base_url}tv/${movieId}?api_key=${config.api_key}&append_to_response=videos,images`
        )
        if (response?.data) {
            data = response.data
        }
    } catch (error) {}
    return data
}

export async function getPopularMovies(page = 1) {
    let data = []
    try {
        const response = await Axios.get(
            `${config.api_base_url}movie/popular?api_key=${config.api_key}&page=${page}`
        )
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {}
    return data
}

export async function getWatchProviders(movieId) {
    let data = []
    try {
        const response = await Axios.get(`https://api.themoviedb.org/3/movie/${movieId}/watch/providers?api_key=${config.api_key}`)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {
        
    }
    return data
}

export async function getWatchProvidersTv(tvId) {
    let data = []
    try {
        const response = await Axios.get(`https://api.themoviedb.org/3/tv/${tvId}/watch/providers?api_key=${config.api_key}`)
        if (response?.data?.results) {
            data = response.data.results
        }
    } catch (error) {
        
    }
    return data
}
