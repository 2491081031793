import { MDBModal } from 'mdbreact';
import React, { useEffect, useRef, useState } from 'react'
import { getMoviesByGenreId, getPopularMovies } from '../../api/api_tmdb';
import { addToCache, getCacheByKey } from '../../helpers/cache'
import { useKeyPress } from '../../hooks/useKeyPress';
//import ScrollGridFullTemplate from './ScrollGridFullTemplate';
import ScrollGridModalContent from './ScrollGridModal';
import ScrollGridLazyTemplate from './templates/ScrollGridFullLazyTemplate';

function ScrollGridFull({genreId, activeColumn, setActiveColumn}) {

	const ITEMS_PER_ROW = 6

	const arrowLeft = useKeyPress("ArrowLeft")
	const arrowRight = useKeyPress("ArrowRight")
	const arrowUp = useKeyPress("ArrowUp")
	const arrowDown = useKeyPress("ArrowDown")
	const left = useKeyPress("a")
	const right = useKeyPress("d")
	const up = useKeyPress("w")
    const down = useKeyPress("s")
    const enter = useKeyPress("Enter")
    const back = useKeyPress("b")

	const movieRef = useRef(null)

	const [movies, setMovies] = useState([])
	const [cols, setCols] = useState(12/ITEMS_PER_ROW)
	const [page, setPage] = useState(1)
    const [activeItem, setActiveItem] = useState(1)
    const [showModal, setShowModal] = useState(false)

    function toggleModal() {
        setShowModal(showModal => !showModal)
	}

	useEffect(() => {
		const userHistory = getCacheByKey("userSelectedGenreMovie")
		console.log(userHistory, "USER HISTORY")
		if (userHistory) {
			const {active_item, active_column, type, genre} = userHistory
			setActiveItem(active_item)
			if (active_column) {
				setActiveColumn(active_column)
			}
			
		}
	}, [])

	useEffect(() => {
		console.log(genreId, "GENREID")
		async function getMovies() {
			const moviesLS = getCacheByKey(`moviesGenre${genreId}`);
			if (moviesLS?.movies?.length > 0 && moviesLS?.page === page) {
                console.log(moviesLS, "MOVIES CACHE")
                setMovies(moviesLS?.movies);
                
			} else {
				let data = []
                console.log("GETTING MOVIES ASYNC")
				if (genreId === 0) {
					data = await getPopularMovies(page)
				} else {
					data = await getMoviesByGenreId(page, genreId)
				}
				if (data?.length > 0) {
	
					if (page > 1) {
						console.log("PAGE > 1")
						let newMovies = movies?.concat(data)
						//updateMovies(movies, data, genreId, page)
						setMovies(newMovies)
					} else {
						console.log("PAGE === 1")
						updateMovies(data, genreId)
						setMovies(data)
					}                  
				}
			}
		}
		getMovies()
    }, [genreId, page])


	function isLeftEdge(activeItem, itemsPerRow) {
		if (activeItem % itemsPerRow === 1) {
			return true
		} else {
			return false
		}
    }


    
    function updateMovies(movies, genreId) {
        const moviesLS = getCacheByKey(`moviesGenre${genreId}`);

        if (!moviesLS) {
            const moviesLocal = {
                date_added: new Date().toISOString(),
                genre_id: genreId,
                movies: movies,
                page: 1
    
            }
            addToCache(`moviesGenre${genreId}`, moviesLocal)
        }

    }

	useEffect(() => {
		if (activeColumn === 2) {

			if (activeItem > movies?.length) {
				setActiveItem(1)
			}
			if (left || arrowLeft) {
				console.log("left")
				if (isLeftEdge(activeItem, ITEMS_PER_ROW)) {
					setActiveColumn(1)
				}
				if (activeItem === 1) {
					setActiveColumn(1)
				} else {
					setActiveItem(activeItem => activeItem - 1)
				}
			}
			if (right || arrowRight) {
				if (activeItem < 1) {
	
				} else if (activeItem <= movies?.length -1) {
					setActiveItem(activeItem => activeItem + 1)
				} else {
					//setActiveItem(activeItem => activeItem + 1)
				}
	
			}
			if (down || arrowDown) {
				if (activeItem <= movies?.length - ITEMS_PER_ROW) {
					setActiveItem(activeItem + ITEMS_PER_ROW)
					
				}
				if (activeItem > movies?.length - ITEMS_PER_ROW) {
					setPage(page => page + 1)
				}
			}
			if (up || arrowUp) {
				if (activeItem > ITEMS_PER_ROW) {
					setActiveItem(activeItem - ITEMS_PER_ROW)
				}
			}
			if (enter) {
				toggleModal()
			}
			if (back) {
				setShowModal(false)
			}
		}


	}, [left, right, up, down, arrowLeft, arrowRight, arrowUp, arrowDown, enter, back])

	useEffect(() => {
		console.log("ACTIVE COL", activeColumn)
	}, [activeColumn])

	useEffect(() => {
		if (activeColumn === 2) {
			let currentHeight
			const activeRow = Math.ceil(activeItem / ITEMS_PER_ROW)

			if (movieRef?.current?.offsetHeight && activeRow > 1) {
				currentHeight = movieRef?.current?.offsetHeight * (activeRow - 1)
				window.scrollTo(0, currentHeight)
			} else {
				window.scrollTo(0, 0)
			}
		}
	}, [movieRef, activeItem, activeColumn])

	useEffect(() => {
        setActiveItem(1)
        setPage(1)
	}, [genreId])

	useEffect(() => {
		// IF ACTIVE COLUM WAS 1 AND SWITCHED TO 2
		setActiveItem(1)
	}, [activeColumn])

	return (
		<React.Fragment>
            <div className="container">
            <ScrollGridLazyTemplate movies={movies} activeItem={activeItem} cols={cols} isActive={activeColumn === 2} perRow={ITEMS_PER_ROW} />
            </div>
			<div className="row no-gutters">
                {/* <ScrollGridFullTemplate items={movies} activeItem={activeItem} itemsPerRow={ITEMS_PER_ROW} /> */}
                {/* {movies?.map((movie, index) => {
                    return (
                        <div ref={movieRef} className={`p-1 col-${cols} ${activeItem === index + 1 && activeColumn === 2 ? "selected" : ""}`} key={index}>
                            <img src={config.api_image_base_url + movie?.poster_path} alt="" />
                        </div>
                    )
                })} */}
                
            </div>
            <MDBModal size="lg" isOpen={showModal} toggle={toggleModal}>
                <ScrollGridModalContent
                    type="movie"
                    movie={movies[activeItem - 1]}
					genreId={genreId}
					activeItem={activeItem}
					activeColumn={activeColumn}
                />
            </MDBModal>
		</React.Fragment>
	)
}
export default ScrollGridFull
