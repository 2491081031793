const baseUrl = 'https://progitek.no'
const movieBlogEndpoint = 'wp-json/wp/v2/filmer'

export async function getFilmPostsWP(page = 1, perPage = 10) {
    let data = []
    try {
        const response = await fetch(
            `${baseUrl}/${movieBlogEndpoint}?page=${page}&per_page=${perPage}`
        )
        data = await response.json()
        console.log(data)
    } catch (error) {}
    return data
}

export async function getSingleFilmPostsWP(id) {
    let data = []
    try {
        const response = await fetch(`${baseUrl}/${movieBlogEndpoint}/${id}`)
        data = await response.json()
        console.log(data)
    } catch (error) {}
    return data
}
