import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

import './Navbar.css'
import StateContext from '../../../StateContext';

function Navbar({activePage}) {

    const [background, setBackground] = useState("transparent");
    const appState = useContext(StateContext)

    window.addEventListener("scroll", () => {
        scrollHandler(window.scrollY);
    })
    
    function scrollHandler(scrollHeight) {
        if (scrollHeight > 100) {
            setBackground("black")
        } else {
            setBackground("transparent");
        }
    }

    return (
        <div className="topBar" style={{backgroundColor: background}}>
            <div className="logoContainer">
                <Link to="/">
                    <img src="https://progitek.no/privat/bp/wp-content/uploads/2020/09/whatsstreaming-1.png" alt="site_logo" />
                </Link>
            </div>
            <ul className="navLinks">
                <li className="">
                    <Link to="/">Hjem</Link>
                </li>
                <li className={activePage === "shows" && "active"}>
                    <Link to="/shows/1">Serier</Link>
                </li>
                <li className="">
                    <Link to="/">Filmer</Link>
                </li>
                {appState.loggedIn && (
                    <li>
                        <Link to={`/user/my-list`} >Min liste</Link>
                    </li>
                )}

            </ul>
            <div className="rightItems ml-auto">
                <Link to="/search-movies"><i className="fas fa-search"></i></Link>
                <Link to="/login"><i className="fas fa-user"></i></Link>
            </div>
        </div>
    )
}
export default Navbar