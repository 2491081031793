import React, { useState } from 'react'
import { auth } from '../../../../firebase';

function RegisterForm() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")

    async function registerHandler(e) {
        e.preventDefault();

        try {
            const credential = await auth.createUserWithEmailAndPassword(email, password)
            if (credential) {
               
                setMessage("Registrert!")
            }
        } catch (error) {
            setError("Noe gikk galt, prøv igjen")
        }

    }

    return (
        <form onSubmit={registerHandler}>
            <div class="md-form">
                {email && <><label for="form1"><small>E-post</small></label><br /><br/></>}
                <input autoComplete={false} type="email" id="form1" className="form-control text-white bgLight" onChange={e => setEmail(e.target.value)} />
                {!email && <label for="form1">E-post</label>}
            </div>
            <div class="md-form">
                {password && <><label for="form1"><small>Passord</small></label><br /><br/></>}
                <input type="password" id="form1" className="form-control text-white" onChange={e => setPassword(e.target.value)} />
                {!password && <label for="form1">Passord</label>}
            </div>

            {error && <div className="alert alert-danger">{error}</div>}
            {message && <div className="alert alert-success">{message}</div>}
            <button className="btn btn-danger btn-block mt-3">Registerer</button>

        </form>
    )
}
export default RegisterForm