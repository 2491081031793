

const rightKeys = [39]
const leftKeys = [37]
export function isKey(keyCode) {
    if (keyCode === 37) {

    }
}

export function isLeft(keyCode) {
    if (leftKeys.includes(keyCode)) {
        return true
    } else {
        return false
    }
}


export function isRight(keyCode) {
    if (rightKeys.includes(keyCode)) {
        return true
    } else {
        return false
    }
}

export function isRightEdge(activeItem, perPage) {
    if (activeItem >= perPage) {
        return true
    } else {
        return false
    }
}
export function isLeftEdge(activeItem) {
    if (activeItem <= 1) {
        return true
    } else {
        return false
    }
}
export function keyPressHandlerReturnActiveItem(key, totalItems, activeItem) {
    let activeItemToReturn = 1;
    console.log(key)
    const left = ""
    const right = ""

    if (key === "left") {
        if (activeItem <= 1) {
            activeItemToReturn = totalItems
        } else {
            activeItemToReturn = activeItem - 1
        }
    }
    if (key === "right") {

        if (activeItem > (totalItems - 1)) {
            activeItemToReturn = 1
        } else {
            activeItemToReturn = activeItem + 1
        }
    }
    return activeItemToReturn
}

export function calculateSlicePosition(key, activeItem, totalItems, perPage, curPosition) {
    let position = curPosition
    if (key === "left") {

        if (activeItem > (totalItems - 1)) {
            position = {
                startPos: 0,
                endPos: perPage
            }
        } else {
            if (curPosition.endPos >= totalItems) {
                position = {
                    startPos: 0,
                    endPos: perPage
                }
            } else {
                position = {
                    startPos: curPosition.startPos + 1,
                    endPos: curPosition.endPos + 1
                }
            }          
        }
    }

    if (key === "right") {
        if (activeItem <= 1) {
            position = {
                startPos: totalItems- perPage,
                endPos: totalItems
            }
        } else {
            if (curPosition.startPos <= 0) {
                position = {
                    startPos: totalItems- perPage,
                    endPos: totalItems
                }
            } else {
                position = {
                    startPos: curPosition.startPos - 1,
                    endPos: curPosition.endPos - 1
                }
            }
        }
    }

    return position
}