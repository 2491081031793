import React, { useEffect, useState } from 'react'
import Genres from '../components/FilmApp/Netflix/Genres'

import '../components/FilmApp/Netflix/Netflix.css'
import Page from '../components/ReusableComponents/Page'
import PreviewVideo from '../components/FilmApp/Netflix/PreviewVideos/PreviewVideo'
import StreamServices from '../components/FilmApp/Netflix/StreamServices'
import UserMoviesScroll from '../components/FilmApp/Netflix/Templates/UserMoviesScroll'
import NavbarDark from '../components/FilmApp/Netflix/Templates/NavbarDark'

function Home() {

	const [currentPage, setCurrentPage] = useState("")

	useEffect(() => {

		setCurrentPage("home")

	}, [])

	return (
		<Page pageTitle="Home">
			<div className="wrapper">
				<NavbarDark activePage={currentPage} />
				<PreviewVideo />
				<div className="container-fluid">
					<UserMoviesScroll />
					<StreamServices />
					<Genres />
				</div>
				<br/>
			</div>
		</Page>
	)
}
export default Home
