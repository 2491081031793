import React, { useState, useEffect } from 'react'
import { getWatchProvidersTv } from '../../../../api/api_tmdb';
import { firestore } from '../../../../firebase';
import TmdbStreamsTemplate from './TmdbStreamsTemplate';

function ShowsStreamServices({movieId}) {

    const [streams, setStreams] = useState([])
    const [tmdbStreams, setTmdbStreams] = useState([])

    useEffect(() => {
        async function getStreamingData() {
            const data = await getWatchProvidersTv(movieId)
            console.log(data['NO'])
            if (data['NO']) {
                setTmdbStreams(data['NO'])
                console.log(data['NO'])
            }
        }
        getStreamingData()
    }, [movieId])
    useEffect(() => {

        try {
            const streamRef = firestore.collection("shows-streaming");
            return streamRef.where("tmdb_id", "==", +movieId).onSnapshot(({docs}) => {
                const streams = docs.map((doc) => {
                    return {id: doc.id, ...doc.data()}
                })
                console.log(streams, movieId)
                setStreams(streams)
            })

        } catch (error) {
            console.log(error)
        }

    }, [movieId])

    async function deleteHandler(e, id) {

        try {
            const streamRef = firestore.collection("shows-streaming").doc(id);
            streamRef.delete();
        } catch (error) {
            
        }

    }

    return (

        <div className="row">
            <div className="col-12">
                <p className="text-white">Streams provided by JustWatch</p>
                {tmdbStreams?.flatrate?.length ? <p className="text-white">Tilgjengelig for strømming på:</p> : ""}
            </div>
            <div className="col-lg-8">
                <div className="row">
                    {tmdbStreams?.flatrate?.map((stream, index) => {
                        return <TmdbStreamsTemplate key={index} stream={stream} />
                    })}
                </div>
            </div>
            <div className="col-12 mt-3">
                {tmdbStreams?.buy?.length ? <p className="text-white">Tilgjengelig for kjøp på:</p> : ""}
            </div>
            <div className="col-lg-8">
                <div className="row">
                    {tmdbStreams?.buy?.map((stream, index) => {
                        return <TmdbStreamsTemplate key={index} stream={stream} />
                    })}
                </div>
            </div>
            <div className="col-12 mt-3">
                {tmdbStreams?.rent?.length ? <p className="text-white">Tilgjengelig for leie på:</p> : ""}
            </div>
            <div className="col-lg-8">
                <div className="row">
                    {tmdbStreams?.rent?.map((stream, index) => {
                        return <TmdbStreamsTemplate key={index} stream={stream} />
                    })}
                </div>
            </div>
        </div>
    )
}
export default ShowsStreamServices