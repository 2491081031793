import React from 'react'
import { config } from '../../../../config/config'

function TmdbStreamsTemplate({stream}) {
    return (
        <div className="col-2 col-lg-1">
            <div className="card">
                <img src={config.api_image_base_url + stream.logo_path} alt={stream.stream_service} />
            </div>
        </div>
    )
}
export default TmdbStreamsTemplate