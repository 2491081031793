import React, { useContext, useEffect, useRef, useState } from 'react'
import DispatchContext from '../../DispatchContext';
import { useKeyPress } from '../../hooks/useKeyPress';

function ScrollGridList({genres, genreId, activeColumn, setActiveColumn}) {

    const appDispatch = useContext(DispatchContext)
    const genreRef = useRef(null)
    const [activeItem, setActiveItem] = useState(1);
    const [currentGenres, setCurrentGenres] = useState(genres || [])

    const up = useKeyPress("w")
    const down = useKeyPress("s")
    const right = useKeyPress("d")

    useEffect(() => {
        const index = genres.map(function (genre) { return genre.id; }).indexOf(genreId);
        const initialGenres = getCurrentGenreOrder(index)
        setCurrentGenres(initialGenres)
    }, [genres])

    function getCurrentGenreOrder(startIndex) {
        let startSlice, endSlice

        startSlice = genres.slice(startIndex, genres?.length)
        if (activeItem > 9) {
            endSlice = genres.slice(0, activeItem)
        } else {
            endSlice = genres.slice(activeItem, genres?.length)
        }
        const genresToShow = startSlice.concat(endSlice)
        return genresToShow
    }

    useEffect(() => {
        if (activeColumn === 1) {
            const genresToShow = getCurrentGenreOrder(activeItem-1)
            setCurrentGenres(genresToShow)
        }
    }, [activeColumn, activeItem])

    useEffect(() => {
        setCurrentGenres(genres)
    }, [genres])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeColumn])

    useEffect(() => {
        if (activeColumn === 1) {
            console.log(genres[activeItem - 1], "GENRE CURRENT")
            if (up) {
                if (activeItem > 1) {
                    setActiveItem(activeItem => activeItem - 1)
                    
                } else {
                    setActiveItem(genres?.length)
                }
            }
            if (down) {
                if (activeItem < genres?.length) {
                    setActiveItem(activeItem => activeItem + 1)
                    
                } else {
                    setActiveItem(1)
                }
            }
            if (right) {
                setActiveColumn(2)
            }
        }
        

    }, [up, down, right])

    useEffect(() => {
        appDispatch({type: 'genreChanged', value: genres[activeItem-1]?.id})
    }, [activeItem])

    useEffect(() => {
        const currentGenre = genres?.filter((genre) => genre.id === genreId)
        console.log(currentGenre[0])
        console.log(genreId)
    }, [genreId])

    return (
        <div className="text-white">
            {activeColumn === 1 && (
                <div>

                    {currentGenres?.map((genre, index) => {

                        const isGenreSelected = index === 0

                        return (
                            <div ref={genreRef} className={`p-2 ${isGenreSelected ? "selected" : ""}`} key={index}>
                                {genre?.name || "GENRE"}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
export default ScrollGridList