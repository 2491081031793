import React from 'react'
import { useParams } from 'react-router-dom'
import SinglePost from '../components/Blog/SinglePost'

function SinglePostPage() {

    const {id} = useParams();

    return <SinglePost id={id} />
}
export default SinglePostPage