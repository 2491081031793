import React, { useEffect, useState } from 'react'
import SingleGenre from './SingleGenre';
import { Link } from 'react-router-dom';
import { getGenres } from '../../../api/api_tmdb';

function Genres() {

	const [genres, setGenres] = useState([])

	useEffect(() => {
		async function fetchGenres() {
			try {
				const data = await getGenres()
				if (data) {
					setGenres(data)
				}
			} catch (error) {
                console.log(error)
			}
		}
		fetchGenres()
	}, [])

	return (
		<React.Fragment>
			{genres?.map((genre, index) => {
				return (
					<div key={index} className="my-4 genreContainer">
						<h4 className="font-weight-bold mt-3"><Link className=" text-white" to={`/movies/genre/${genre.id}`}>{genre.name}</Link></h4>
						<SingleGenre genre={genre} />
					</div>
				)
			})}
		</React.Fragment>
	)
}
export default Genres
