import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getSingleMovieById, getSingleTvById } from '../../api/api_tmdb'
import { config } from '../../config/config'
import { addToCache } from '../../helpers/cache'
import { useKeyPress } from '../../hooks/useKeyPress'

function ScrollGridModalContent({ movie, type, genreId, activeItem, activeColumn }) {
    const [singleMovie, setSingleMovie] = useState()
    const play = useKeyPress("Enter")

    const history = useHistory()

    useEffect(() => {
        if (play) {
            history.push(`/video/${movie.id}?genre_id=${genreId || 28}`)
            // Save genre and movie in LS
            const userHistory = {
                genre: genreId,
                movie_id: movie.id,
                type: type,
                active_item: activeItem,
                active_column: activeColumn
            }
            addToCache("userSelectedGenreMovie", userHistory)
        }
    }, [play])


    useEffect(() => {
        console.log(movie, type)
        async function getSingleMovieModal() {
            if (type === 'tv') {
                const data = await getSingleTvById(movie.id)
                if (data) {
                    console.log(data)
                    setSingleMovie(data)
                }
            } else {
                const data = await getSingleMovieById(movie.id)
                if (data) {
                    console.log(data)
                    setSingleMovie(data)
                }
            }
        }
        getSingleMovieModal()
    }, [movie])

    return (
        <div className="text-white" style={{ backgroundColor: 'black' }}>
            <div className="bg-image">
                <img
                    className="image-fixed-wide"
                    src={
                        singleMovie?.backdrop_path
                            ? config.api_image_base_url +
                              singleMovie?.backdrop_path
                            : config.default_backdrop_path
                    }
                    alt=""
                />
                <div
                    className="mask"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                >
                    <div className="text-image-container bottom-left">
                        <h2 className="font-weight-bold">
                            {type === 'tv'
                                ? singleMovie?.name
                                : singleMovie?.title}
                        </h2>
                        <div>
                            <button className="netflix-button">
                                <i className="fas fa-caret-right"></i> Spill av
                            </button>
                            <button className="button-circle netflix-button-circle">
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container p-3">
                <div
                    dangerouslySetInnerHTML={{ __html: singleMovie?.overview }}
                ></div>
                <div className="my-3"></div>
            </div>
        </div>
    )
}
export default ScrollGridModalContent
