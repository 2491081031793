import React, { useState, useEffect, useRef } from 'react'

import './Preview.css'
import { Link } from 'react-router-dom';
import PreviewInterstellar from './PreviewInterstellar';
import PreviewGladiator from './PreviewGladiator';
import PreviewSavingPrivateRyan from './PreviewSavingPrivateRyan';

function PreviewVideo() {

	const [muted, setMuted] = useState(true)
	const [showVideo, setShowVideo] = useState(true);
	const [previewTitle, setPreviewTitle] = useState(1);
	const [previewVideo, setPreviewVideo] = useState(null)
	const previewBottomRef = useRef(null)
	const [randNum, setRandNum] = useState()

	const previews = [
		{
			name: "Interstellar",
			thumbnail: "https://image.tmdb.org/t/p/original//xJHokMbljvjADYdit5fK5VQsXEG.jpg",
			trailer: "https://progitek.no/privat/bp/wp-content/uploads/2020/09/interstellar-16-9.mp4",
			stream_service: "https://progitek.no/wp-content/uploads/2020/05/netflix.png",
			tmdb_id: "157336"
		},
		{
			name: "Saving Private Ryan",
			thumbnail: "https://image.tmdb.org/t/p/original//hjQp148VjWF4KjzhsD90OCMr11h.jpg",
			trailer: "https://progitek.no/privat/bp/wp-content/uploads/2020/09/saving-private-ryan_trailer.mp4",
			stream_service: "https://progitek.no/wp-content/uploads/2020/05/prime.jpg",
			tmdb_id: "857"
		},
		{
			name: "Gladiator",
			thumbnail: "https://image.tmdb.org/t/p/original//aZtwH3RQ0L8jbInxr7OSc9tlGMJ.jpg",
			trailer: "https://progitek.no/privat/bp/wp-content/uploads/2020/09/gladiator-trailer.mp4",
			stream_service: "https://progitek.no/wp-content/uploads/2020/05/netflix.png",
			tmdb_id: "98"
		}
	]
	useEffect(() => {

		if (previews) {
			const randomMovie = Math.floor(Math.random()*previews.length)
			setRandNum(randomMovie)
			console.log(randomMovie)
			setPreviewTitle(previews[randomMovie]);
			if (previews && previews[randomMovie]) {
				setPreviewVideo(`<source src="${previews[randomMovie].trailer}" type="video/mp4"></source>`)
			}
		}
	}, [])

	function toggleMute() {
		if (muted) {
			setMuted(false)
		} else {setMuted(true)}
	}

	function scrollDown() {
		window.scrollTo(0, 600)
	}

	return (
		<>

		<div className="previewContainer">

			<div className="previewImageContainer">
				<img 
					src={previewTitle && previewTitle.thumbnail}
					alt="preview" 
					className="preview"
					hidden={showVideo}
				/>
			</div>
			{randNum === 0 && <PreviewInterstellar randNum={randNum} setShowVideo={setShowVideo} showVideo={showVideo} muted={muted} />}
			{randNum === 1 && <PreviewSavingPrivateRyan randNum={randNum} setShowVideo={setShowVideo} showVideo={showVideo} muted={muted} />}
			{randNum === 2 && <PreviewGladiator randNum={randNum} setShowVideo={setShowVideo} showVideo={showVideo} muted={muted} />}

			<div className="previewOverlay">

				<div className="mainDetails">

					<h3>{previewTitle && previewTitle.name}</h3>
					<div className="buttons">
						{previewTitle && (
							<>

								<Link className="preview-button" to={`/movies/single/${previewTitle.tmdb_id}`}>
									<button>
										<i className="fas fa-play"></i> Vis
									</button>
								</Link>

								<button onClick={toggleMute}>
									<i className={muted ? "fas fa-volume-mute" : "fas fa-volume-up"}></i> {muted ? "Lyd" : "Mute"}
								</button>

							</>
						)}
						<div>
							{previewTitle && (
								<>
									<p className="text-white">Tilgjengelig på:</p>
									<div className="row">
										<div className="col-3 col-lg-2">
											<img src={previewTitle && previewTitle.stream_service} alt="Netflix" />
										</div>
									</div>
								</>
							)}
							{previewTitle && (
								<div onClick={scrollDown} className="text-center text-white">
									<i className="fas fa-arrow-down fa-3x"></i>
								</div>
							)}
						</div>
					</div>

				</div>
				<div ref={previewBottomRef}></div>
			</div>

		</div>
		</>
	)
}
export default PreviewVideo
