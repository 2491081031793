import React from 'react'
import { useParams } from 'react-router-dom'
import SinglePostWp from '../components/Blog/SinglePostWp'
import NavbarDark from '../components/FilmApp/Netflix/Templates/NavbarDark'

function SinglePostWpPage() {
    const { id } = useParams()

    return (
        <React.Fragment>
            <NavbarDark color="elegant-color-dark" />
            <div className="container py-5">
                <SinglePostWp id={id} />
            </div>
        </React.Fragment>
    )
}
export default SinglePostWpPage
