import React, { useContext, useEffect, useState } from 'react'

import StateContext from '../../../StateContext'
import UserMovies from './Templates/UserMovies'
import NavbarDark from './Templates/NavbarDark'
import UserShows from './Templates/UserShows'

function MyList() {

    const appState = useContext(StateContext)
    const [currentPage, setCurrentPage] = useState("");
    const [activeItem, setActiveItem] = useState("1");

    useEffect(() => {

        setCurrentPage("mylist")

    }, [])

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    return (
        <div className="wrapper">
            <NavbarDark activePage={currentPage} />
            <div className="py-5 container-fluid">
                <div className="row mt-5">
                    <div className="col-6">
                        <button onClick={() => toggle("1")} className={`btn btn-primary btn-block ${activeItem === "1" && "active"}`}>Filmer</button>
                    </div>
                    <div className="col-6">
                        <button onClick={() => toggle("2")} className={`btn btn-primary btn-block ${activeItem === "2" && "active"}`}>Serier</button>
                    </div>
                </div>
                {activeItem === "1" && <UserMovies userId={appState.userId} />}
                {activeItem === "2" && <UserShows userId={appState.userId} />}
            </div>
        </div>
    )
}
export default MyList