import React from 'react'
import Posts from '../components/Blog/Posts'
import PostsWp from '../components/Blog/PostsWp'
import NavbarDark from '../components/FilmApp/Netflix/Templates/NavbarDark'
import Page from '../components/ReusableComponents/Page'

function PostsPage() {
    return (
        <Page pageTitle="Posts">
            <NavbarDark color="elegant-color-dark" />
            <Posts />
            <PostsWp />
        </Page>
    )
}
export default PostsPage
