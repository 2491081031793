import React from 'react'
import AccordionWrapper from '../components/Animations/AccordionWrapper'
import AnimatedCarousel from '../components/Carousel/templates/AnimatedCarousel'
import MDBCarouselTemplate from '../components/Carousel/templates/MDBCarouselTemplate'

function TestPage() {

    const IMAGES = ["https://image.tmdb.org/t/p/w1280/nMfH0LMMFk2ovyURkYvyTI99nSo.jpg","https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg","https://mdbootstrap.com/img/Photos/Slides/img%20(47).jpg"]

    return (
        <div>
            <AccordionWrapper />
            <MDBCarouselTemplate images={IMAGES} />
            <AnimatedCarousel images={IMAGES} />
        </div>
    )
}
export default TestPage