import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import Axios from 'axios';
import Navbar from './Navbar';
import Preview from './Preview';
import Page from '../../ReusableComponents/Page';

function SingleGenrePage() {

    const {genreId} = useParams();


    const [movies, setMovies] = useState([])

    const [pageCount, setPageCount] = useState(1)

    const IMAGE_URL = "https://image.tmdb.org/t/p/w1280"

    const api_key = "7e21606276f58bbdfa39c8cdd8e051f5"
    const url = `https://api.themoviedb.org/3/discover/movie?api_key=${api_key}&with_genres=${genreId}&page=${pageCount}`;

    const [genre, setGenre] = useState([])

    const genre_url = `https://api.themoviedb.org/3/genre/movie/list?api_key=${api_key}`;

    useEffect(() => {

        async function fetchGenres() {
            try {
                const response = await Axios.get(genre_url);
                const currentGenre = response.data.genres.filter(genre => {
                    console.log(genre.id, "===", +genreId)
                    return genre.id === +genreId;
                })
                setGenre(currentGenre[0]);
            } catch (error) {
                
            }
        }
        fetchGenres()


    }, [])

    useEffect(() => {
        
        async function fetchMovies() {

            try {
                const response = await Axios.get(url)
                if (response.data.results) {
                    let moviesArray
                    if (movies && pageCount > 1) {
                        moviesArray = movies.concat(response.data.results);
                        
                    } else {
                        moviesArray = response.data.results
                    }
                    setMovies(moviesArray)
                    
                }
            } catch (error) {
                console.log(error)
            }

        }
        fetchMovies()

    }, [genreId, pageCount])

    return (
        <Page>
            <Navbar />    
            <Preview />
            {genre && console.log(genre)}
            <div className="wrapper py-5">
                <div className="container-fluid">
                    <h3 className="font-weight-bold text-white"><span className="text-muted">Filmer</span> - {genre && genre.name}</h3>
                    <div className="row no-gutters pt-5">
                        {movies && movies.map((movie, index) => {
                            return (
                                <div className="col-4 col-md-3 col-lg-2 pt-1 pr-1" key={index}>
                                    <Link to={`/movies/single/${movie.id}`}>
                                        <img src={`${IMAGE_URL}/${movie.poster_path}`} alt={movie.title} />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <button className="btn btn-block btn-lg btn-warning text-dark my-3" onClick={() => setPageCount(pageCount+1)}>Last mer</button>
                    </div>
                </div>
            </div>
        </Page>
    )
}
export default SingleGenrePage