import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Preview from './Preview';
import Loader from '../../Loader/Loader';
import Page from '../../ReusableComponents/Page';
import NavbarDark from './Templates/NavbarDark';
import CreditsMovie from './Templates/CreditsMovie';
import ImagesMovie from './Templates/ImagesMovie';
/* import AddStreamService from './Templates/AddStreamService';
import RemoveStreamService from './Templates/RemoveStreamService'; */
import TrailersMovie from './Templates/TrailersMovie';
import RecommendedMovies from './Templates/RecommendedMovies';
import SimilarMovies from './Templates/SimilarMovies'
import { getSingleMovieById } from '../../../api/api_tmdb';
import MovieStreamServices from './MovieStreamServices';

function SingleMovie() {

    const {id} = useParams();

    const [movie, setMovie] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [activeItem, setActiveItem] = useState("1")

    useEffect(() => {
        
        async function getSingleMovie() {
            setIsLoading(true)
            try {
                //const response = await fetch(`https://api.themoviedb.org/3/movie/${id}?api_key=${api_key}&append_to_response=videos,images`);
                const data = await getSingleMovieById(id)
                if (data) {
                    setMovie(data);
                } else {
                    console.log("Kunne ikke laste film")
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getSingleMovie()

    }, [id])

    function toggle(tab) {
        if (activeItem !== tab) {
            setActiveItem(tab)
        }
    }

    if (isLoading) return <Loader />

    return (
        <Page pageTitle={movie ? movie.title : "Film"}>
            <div className="wrapper">
                <NavbarDark />
                {movie && <Preview movie={movie} />}
                {movie && <div className="my-3 px-5"><MovieStreamServices movieId={movie.id} /></div>}
                <br />
                <div className="container-fluid mt-5">
                    <div className="row mt-3">
                        <div className="col-lg-3 col-md-4 col-12">
                            <button onClick={() => toggle("1")} className={`btn btn-primary btn-block ${activeItem === "1" && "active"}`}>Cast</button>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <button onClick={() => toggle("2")} className={`btn btn-primary btn-block ${activeItem === "2" && "active"}`}>Images</button>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <button onClick={() => toggle("3")} className={`btn btn-primary btn-block ${activeItem === "3" && "active"}`}>Trailer</button>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12">
                            <button onClick={() => toggle("4")} className={`btn btn-primary btn-block ${activeItem === "4" && "active"}`}>Similar</button>
                        </div>
                    </div>
                    {activeItem === "1" && <CreditsMovie movieId={id} />}
                    {activeItem === "2" && <ImagesMovie movieId={id} imagesMovie={movie?.images} />}
                    {activeItem === "3" && <TrailersMovie trailers={movie?.videos?.results} />}
                    {activeItem === "4" && (
                        <React.Fragment>
                            <RecommendedMovies movieId={id} />
                            <SimilarMovies movieId={id} />
                        </React.Fragment>
                    )}
                </div>
            </div>
        </Page>
    )
}
export default SingleMovie
