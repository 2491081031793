import React, { useState, useContext } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { auth } from '../../../firebase'
import StateContext from '../../../StateContext'
import DispatchContext from '../../../DispatchContext'
import LoginForm from './Templates/LoginForm'
import RegisterForm from './Templates/RegisterForm'

function LoginPage() {
    const appState = useContext(StateContext)

    const [mode, setMode] = useState('login')

    if (appState.loggedIn) return <Redirect to="/user" />

    return (
        <div className="wrapper intro-login">
            <div className="mask rgba-black-strong">
                <Link to="/">
                    <img
                        src="https://progitek.no/privat/bp/wp-content/uploads/2020/09/whatsstreaming-1.png"
                        className="pt-4 pl-4"
                        style={{ maxWidth: '200px' }}
                    />
                </Link>
                <div style={{ minHeight: '70vh' }}>
                    <div className="col-md-6 col-lg-4 mx-auto py-5">
                        <div className="card card-body card-transparent">
                            <div className="col-md-11 mx-auto py-5">
                                {mode === 'login' ? (
                                    <>
                                        <h4 className="text-white font-weight-bold">
                                            Logg inn
                                        </h4>
                                        <LoginForm />
                                        <div className="text-center text-white pt-2">
                                            <button
                                                className="btn btn-block btn-transparent  text-white"
                                                onClick={() =>
                                                    setMode('register')
                                                }
                                            >
                                                Registrering
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h4 className="text-white font-weight-bold">
                                            Registrering
                                        </h4>
                                        <RegisterForm />
                                        <div className="text-center pt-2 text-white">
                                            <button
                                                className="btn btn-block btn-transparent  text-white"
                                                onClick={() => setMode('login')}
                                            >
                                                Logg inn
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginPage
