import React, {useState, useEffect, useContext} from 'react'
import {useParams} from 'react-router-dom'
import { getSingleTvById, getWatchProvidersTv } from '../../../api/api_tmdb';
import StateContext from '../../../StateContext';
import NavbarDark from '../Netflix/Templates/NavbarDark'
import AddStreamServiceTV from './Templates/AddStreamServiceTV';
import ShowsStreamServices from './Templates/ShowsStreamServices';
import WatchListHandlerTV from './Templates/WatchListHandlerTV';

function SingleTV() {

    const appState = useContext(StateContext)

    const [isLoading, setIsLoading] = useState(false)
    const [moviesLoaded, setMoviesLoaded] = useState(false);
    const [movie, setMovie] = useState()
    const [isShowAdded, setIsShowAdded] = useState(false)
    const {id} = useParams()

    const getYear = (date) => {
        return new Date(date).getFullYear()
    }



    useEffect(() => {
        async function getSingleTV() {
            setIsLoading(true)
            try {
                const data = await getSingleTvById(id)
                if (data) {
                    setMovie(data);
                    setMoviesLoaded(true)
                    setIsShowAdded(true)
                } else {
                    console.log("Kunne ikke laste film")
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
        getSingleTV()
    }, [id])

    

    if (isLoading) return <div>Laster...</div>

    return (
        <>
        <NavbarDark />
        {moviesLoaded &&
            <div 
                className="background-image" 
                style={{
                    backgroundImage: "url('https://image.tmdb.org/t/p/w1280/" + movie.backdrop_path + "')",
                    backgroundSize: "cover",
                    backgroundColor: "rgba(0, 0, 0, 0.9)"
                }} 
            >
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.7)"}}>
                    <br /><br />
                    <div className="container py-4">
                        <div className="row">
                            <div className="col-md-4">
                                
                                {movie.poster_path != null ? 
                                    <img src={`https://image.tmdb.org/t/p/w1280${movie.poster_path}`} className="img-fluid" alt="tv-poster" /> : 
                                    <img src={`https://complexmovieapp.herokuapp.com/images/film-poster-placeholder.png`} alt="tv-poster" className="img-fluid" />}
                            </div>
                            <div className="col-md-8 text-white">
                                <div className="container">

                                    <h2>{movie.original_name} <span className="grey-text"><small>{getYear(movie.first_air_date)}</small></span></h2>
            
                                    <h3 className="my-4 font-weight-bold">
                                        <span className="bg-success px-2 mr-2">{movie.vote_average}</span>
                                        <span>Brukeranmeldelser</span>
                                    </h3>

                                    <h4 className="font-weight-bold">Oversikt</h4>
                                    <p>{movie.overview}</p>
                                    
                                    <div className="mainDetails">
                                        <div className="buttons">
                                            <WatchListHandlerTV movie={movie} />
                                        </div>
                                    </div>
 
                                    
                                    

                                </div>
                            </div>
                        </div>
                        <br />
                        <ShowsStreamServices movieId={id} />
                        {appState.loggedIn && <AddStreamServiceTV tv={movie} />}
                        
                    </div>
                </div>
            </div>
        }
        </>
    
    )

}
export default SingleTV