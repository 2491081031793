import React from 'react'

function PreviewSavingPrivateRyan({showVideo, setShowVideo, muted, randNum}) {



    return (
        <video hidden={!showVideo} onEnded={() => setShowVideo(false)} autoPlay muted={muted} className="previewVideo">
            <source src={"https://progitek.no/privat/bp/wp-content/uploads/2020/09/saving-private-ryan_trailer.mp4"} type="video/mp4"></source>
        </video>
    )
}
export default PreviewSavingPrivateRyan