import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSingleFilmPostsWP } from '../../api/api_wp'
import Loader from '../Loader/Loader'

function SinglePostWp({ id }) {
    //const {id} = useParams()

    const [post, setPost] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function getPost() {
            setIsLoading(true)
            const data = await getSingleFilmPostsWP(id)
            if (data) {
                setPost(data)
            }
            setIsLoading(false)
        }
        getPost()
    }, [id])

    return (
        <div>
            {isLoading && <Loader />}
            <h2 className="font-weight-bold">{post?.title?.rendered}</h2>
            <div>
                <img
                    src={post?.featured_image_urls?.large || ''}
                    alt=""
                    className="img-fluid"
                />
            </div>

            <hr />

            <div
                dangerouslySetInnerHTML={{ __html: post?.content?.rendered }}
            />
        </div>
    )
}
export default SinglePostWp
