import React, { useEffect } from 'react'
import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        }
    },
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity
}

function AnimatedImage({ images, isActive, left, right, movieRef }) {
    const [[page, direction], setPage] = useState([0, 0])

    const imageIndex = wrap(0, images.length, page)

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection])
    }

    useEffect(() => {
        if (isActive) {
            if (left) {
                paginate(-1)
            }
            if (right) {
                paginate(1)
            }
        }
    }, [left, right])

    return (
        <div className={isActive ? '' : ''}>
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    ref={movieRef}
                    key={page}
                    className={
                        isActive && movieRef?.current?.offsetHeight
                            ? 'animated-image'
                            : isActive
                            ? 'selected animated-image'
                            : ''
                    }
                    src={images[imageIndex]}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                        x: { type: 'spring', stiffness: 50, damping: 10 },
                        opacity: { duration: 0.4 },
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x)

                        if (swipe < -swipeConfidenceThreshold) {
                            paginate(1)
                        } else if (swipe > swipeConfidenceThreshold) {
                            paginate(-1)
                        }
                    }}
                />
            </AnimatePresence>
        </div>
    )
}
export default AnimatedImage
