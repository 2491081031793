import React, { useState, useEffect, useRef } from 'react'

import './PreviewVideos/Preview.css'
import { firestore } from '../../../firebase';
import { Link } from 'react-router-dom';
import WatchListHandler from './Templates/WatchListHandler';
import { getPreviewByStreamId, getPreviews } from '../../../firebase/firebaseRequestHelper';
import { config } from '../../../config/config';
import PreviewPlayButton from './Templates/PreviewPlayButton';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Preview({movie, stream_id}) {

    const IMAGE_URL = "https://image.tmdb.org/t/p/original/"

    const [muted, setMuted] = useState(true)
    const [showVideo, setShowVideo] = useState(false);
    const [previews, setPreviews] = useState([])
    const [previewTitle, setPreviewTitle] = useState(1);
    const [previewVideo, setPreviewVideo] = useState(null)
    const previewBottomRef = useRef(null)
    const [rating, setRating] = useState(0)
    const [streamProvider, setStreamProvider] = useState()
    const circleStyles = {
        pathColor: config.theme.main_color,
        textColor: config.theme.main_color
    }

    useEffect(() => {
        if (stream_id) {
            try {
                const streamRef = firestore.collection("stream-services").doc(stream_id)
                const stream = streamRef.onSnapshot(doc => {
                    return {id: doc.id, ...doc.data()}
                })
                setStreamProvider(stream)
            } catch (error) {     
            }
        }
    }, [stream_id])

    useEffect(() => {
        console.log(movie)
        if (movie) {
            const movieRating = Math.round((movie?.vote_average / 10) * 100)
            setRating(movieRating)
        }
    }, [movie])

    useEffect(() => {
        if (!movie) {
            async function fetchPreviews() {
                if (stream_id) {
                    // Get all movies from stream provider
                    getPreviewByStreamId(setPreviews, stream_id)
                } else {
                    getPreviews(setPreviews)
                }
            }
            fetchPreviews()
        }
    }, [movie])

    useEffect(() => {
        if (previews) {
            const randomMovie = Math.floor(Math.random()*previews.length)
            console.log(randomMovie)
            setPreviewTitle(previews[randomMovie]);
            if (previews && previews[randomMovie]) {
                setPreviewVideo(`<source src="${previews[randomMovie].trailer}" type="video/mp4"></source>`)
            }
        } else {
            setPreviewTitle(movie)
        }
    }, [previews])

    function toggleMute() {
        if (muted) {
            setMuted(false)
        } else {setMuted(true)}
    }
    function scrollDown() {
        window.scrollTo(0, 600)
    }

    return (
        <>
        <div className="previewContainer">   
            <div className="previewImageContainer">
                {movie && 
                    <img 
                        src={movie.backdrop_path ? IMAGE_URL + movie.backdrop_path : config.default_backdrop_path}                    
                        alt="preview" 
                        className="preview"
                        hidden={showVideo} 
                    />
                }
                {previewTitle && (
                    <img 
                        src={previewTitle?.thumbnail ? previewTitle.thumbnail : config.default_backdrop_path}
                    />
                )}
            </div>
            <video hidden={!showVideo} onEnded={() => setShowVideo(false)} autoPlay muted={muted} className="previewVideo">
                <div dangerouslySetInnerHTML={{__html: previewVideo}} />
            </video>
            <div className="previewOverlay">
                <div className="mainDetails">       
                    <h3>{movie ? movie.title : previewTitle && previewTitle.name}</h3>
                    <div className="buttons">
                        {previewTitle && (
                            <>                      
                                <Link className="preview-button" to={`/movies/single/${previewTitle.tmdb_id}`}>
                                    <button>
                                        <i className="fas fa-play"></i> Vis
                                    </button>
                                </Link>
                                <button onClick={toggleMute}>
                                    <i className={muted ? "fas fa-volume-mute" : "fas fa-volume-up"}></i> {muted ? "Lyd" :  "Mute"}
                                </button>
                            </>
                        )}
                        {movie && (
                            <>
                                <PreviewPlayButton movie={movie} />
                                <WatchListHandler movie={movie} />
                                {rating > 0 && (
                                    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                                        <div className="col-4">
                                            <CircularProgressbar value={rating} text={`${rating}%`} styles={buildStyles(circleStyles)} />;
                                        </div> 
                                    </div>
                                )}
                            </>
                        )}
                        <div>            
                            <div className="text-white my-3 col-padding">
                                {movie?.overview}
                            </div>
                            
                            {previewTitle && !streamProvider && (
                                <>
                                    <p className="text-white">Tilgjengelig på:</p>
                                    <div className="row">
                                        <div className="col-3 col-lg-2">
                                            <img src={previewTitle && previewTitle.stream_service} alt="Netflix" />
                                        </div>
                                    </div>
                                </>
                            )}
                            {streamProvider && (
                                <>
                                    <p className="text-white">Tilgjengelig på:</p>
                                    <div className="row">
                                        <div className="col-3 col-lg-2">
                                            <img src={streamProvider.image} alt={streamProvider.name} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {previewTitle && (
                                <div onClick={scrollDown} className="text-center text-white">
                                    <i className="fas fa-arrow-down fa-3x"></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div ref={previewBottomRef}></div>
            </div>
        </div>
        </>
    )
}
export default Preview