import React, { useEffect, useState } from 'react'

import { getFilmPostsWP } from '../../api/api_wp'
import PostListTemplateWp from './templates/PostListTemplateWp'

function PostsWp() {
    const [posts, setPosts] = useState([])

    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)

    useEffect(() => {
        async function getFilmPosts() {
            const data = await getFilmPostsWP(page, perPage)
            setPosts(data)
        }
        getFilmPosts()
    }, [page, perPage])

    return (
        <div className="container">
            <div className="row my-4">
                {posts?.map((post, index) => {
                    return <PostListTemplateWp key={index} post={post} />
                })}
            </div>
        </div>
    )
}
export default PostsWp
