export function dateInputFormatter(datePost) {
    const date = new Date(datePost)

    const inputFormat = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    return inputFormat
}

export function getYearByDate(date) {
    const dateObj = new Date(date)
    return dateObj.getFullYear()
}

export function isToday(date) {

    let isToday = false
    const today = new Date()

    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth() + 1
    const currentDay = today.getDate()

    const dateObj = new Date(date);
    const year = dateObj.getFullYear()
    const month = dateObj.getMonth()+1
    const day = dateObj.getDate()

    if (currentYear === year && currentMonth === month && currentDay === day) {
        isToday = true
    }
    return isToday
}
export function daysSinceDate(date) {
    const today = new Date()
    const dateObj = new Date(date);

    const differenceInTime = today.getTime() - dateObj.getTime()
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays


}