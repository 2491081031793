export const getItemsToShowByPosition = (
    movies,
    activeItem,
    PER_PAGE,
    SCROLL
) => {
    let newMoviesToShow = movies?.slice(0, PER_PAGE)
    const endMoviesCount = PER_PAGE - (movies.length - (activeItem - 1))
    const endMovies = movies.slice(0, endMoviesCount)
    if (SCROLL) {
        // SCROLL IF ACTIVEITEM IS BIGGER THAN LENGTH OF VIEW MOVIES
        if (activeItem > PER_PAGE) {
            //setActiveSlicedItem(PER_PAGE)

            newMoviesToShow = movies.slice(activeItem - PER_PAGE, activeItem)
        } else {
            newMoviesToShow = movies.slice(0, PER_PAGE)
            //setActiveSlicedItem(activeItem)
        }
    }
    if (!SCROLL) {
        if (activeItem > PER_PAGE) {
            //

            const startMovies = movies.slice(activeItem - 1, movies.length)
            newMoviesToShow = startMovies.concat(endMovies)
        } else {
            // CHECK IF REMAINING MOVIES COUNT IS ENOUGH TO FILL GRID
            if (movies.length - activeItem < PER_PAGE) {
                const startMovies = movies.slice(
                    activeItem - 1,
                    PER_PAGE + activeItem
                )
                newMoviesToShow = startMovies.concat(endMovies)
            } else {
                newMoviesToShow = movies.slice(
                    activeItem - 1,
                    PER_PAGE + activeItem - 1
                )
            }
        }
    }

    return newMoviesToShow
}
