import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { firestore } from '../../../firebase';
import Page from '../../ReusableComponents/Page';
import Navbar from './Navbar';
import Preview from './Preview';

function MoviesStreamServicePage() {

    const {id} = useParams();

    const IMAGE_URL = "https://image.tmdb.org/t/p/w1280"
    const [movies, setMovies] = useState([]);
    const [limit, setLimit] = useState(30)

    useEffect(() => {

        try {
            const moviesRef = firestore.collection("movies-streaming");
            moviesRef.where("stream_service_id", "==", id).limit(limit).onSnapshot(({docs}) => {
                const movies = docs.map(doc => {
                    return {id: doc.id, ...doc.data()}
                })
                setMovies(movies)
            })
        } catch (error) {
            
        }

    }, [id, limit]);

    return (
        <Page pageTitle={"Strømmetjeneste"}>
            <div className="wrapper">
                <Navbar />
                <Preview stream_id={id} />
                <div className="col-lg-8 mx-auto">
                    <div className="row no-gutters py-3">
                        {movies && movies.map((movie, index) => {
                            return (
                                <div className="col-4 col-md-3 col-lg-2 pt-2 pr-2" key={index}>
                                    <div className="card">
                                        <Link to={`/movies/single/${movie.tmdb_id}`}>
                                            <img className="img-fluid" src={`${IMAGE_URL}/${movie.movie_poster}`} alt={movie.movie_title} />
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="pb-3">
                        <button className="btn btn-danger btn-lg btn-block" onClick={() => setLimit(limit + 30)}>Last mer</button>
                    </div>
                </div>
            </div>
        </Page>
    )
}
export default MoviesStreamServicePage