export const config = {
    theme: {
        main_color: "#ffc107"
    },
    cacheDuration: 7, // days
    site_title: 'WhatsStreaming',
    site_logo: "https://progitek.no/privat/bp/wp-content/uploads/2020/09/whatsstreaming-1.png",
    api_key: '7e21606276f58bbdfa39c8cdd8e051f5',
    api_base_url: 'https://api.themoviedb.org/3/',
    api_image_base_url: 'https://image.tmdb.org/t/p/w1280',
    default_movie_poster:
        'https://progitek.no/privat/bp/wp-content/uploads/2021/02/film-poster-placeholder.png',
    default_backdrop_path:
        'https://progitek.no/privat/bp/wp-content/uploads/2020/12/pexels-kin-pastor-777059.jpg',
    cache_duration: 7,
    youtube_base: "https://www.youtube.com/embed/"
}
