import React, {useState, useEffect} from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Axios from 'axios'
import Lightbox from "react-image-lightbox";
import "./Lightbox.css";
import Loader from "../Loader/Loader";

function LightboxPage({imagesArray, imageBaseUrl}) {

    const [perPage, setPerPage] = useState(9)
    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [images, setImages] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (imagesArray) {
            const images = imagesArray.map(image => {
                return `${imageBaseUrl}${image.file_path}`
            })
            setImages(images)
        }

    }, [imagesArray])


    const renderImages = () => {
        let photoIndex = -1;
        

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="3" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={ ()=> {
                                setIsOpen(true);
                                setPhotoIndex(privateKey);
                            }}
                        />
                    </figure>
                </MDBCol>
                );
        })
    }

if (isLoading) return <Loader/>

  return (
      <div className="mt-5">
        <div className="mdb-lightbox no-margin">
          <MDBRow>
            {renderImages()}
          </MDBRow>
        </div>
        {isOpen && (
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                imageTitle={photoIndex + 1 + "/" + images.length}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + images.length -1) % images.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                }
            />
        )}
      </div>
    );
  
}

export default LightboxPage;