import React from "react";

import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask } from
"mdbreact";

const MDBCarouselTemplate = ({images}) => {

    return (
        <MDBCarousel
            activeItem={1}
            length={images?.length}
            showControls={true}
            className="z-depth-1"
        >
            <MDBCarouselInner>
                {images?.map((image, index) => {
                    return (
                        <MDBCarouselItem key={index} itemId={index+1}>
                            <MDBView className="bg-dark">
                                <img
                                className="d-block w-100"
                                src={image || "https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg"}
                                alt="First slide"
                                />
                            <MDBMask overlay="black-light" />
                            </MDBView>
                        </MDBCarouselItem>
                    )
                })}      
            </MDBCarouselInner>
        </MDBCarousel>
    );
}

export default MDBCarouselTemplate;